import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { transaction, transactionbyid } from "../../services/Bank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import Accordion from "react-bootstrap/Accordion";
import ViewTransaction from "./ViewTransaction";
import PaginatedTable from "../common/PaginatedTable";
import ViewIcon from "../../assets/images/view_icon.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseIcon from "../../assets/images/close_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import SelectdateIcon from "../../assets/images/selectdate_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { convertToISOString } from "../../services/common.service";

const Transactions = ({ back }) => {
  const { id } = useParams();

  const user = useSelector(selectCurrentUser)?.userDetail;
  const [transactiondetails, settransactiondetails] = useState(null);

  const [Viewtransaction, setViewtransaction] = useState(false);
  const [TransactionTable, setTransactioTable] = useState(true);
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };
  const [purchasetype, setpurchasetype] = useState("");

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };
  const getAlltransactions = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        statusfilter: purchasetype || "all",
        brand_id: id,
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),


        // search:undefined
      };
      const resp = await transaction(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        settransactiondetails(resp?.data?.data);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const handleShowView = (tdetails) => {
    setTransactioTable(false);
    setViewtransaction(true);
  };

  // const back = (cond = true) => {
  //   // cond && getAlloffer();
  //   // setShowCreate(false);
  //   // setShowEdit(false);
  //   setViewtransaction(false);
  //   setVoucherTable(true);
  // };

  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <img
          src={ViewIcon}
          alt="ViewIcon"
          onClick={() => handleShowView(row.master_transaction_id)}
        />
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    {
      heading: () => "	Customer Name/ Customer Mobile ",
      cell: (row) =>
        row["customer_name"] === " " ? "-" : row["customer_name"],
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Purchase Type",
      cell: (row) => (row["purchase_type"] ? row["purchase_type"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    {
      heading: () => "	Total Amount",
      cell: (row) => {
        const amount = row["total_amount"];
        return amount ? formatCurrency(amount) : "-";
        // return row['total_amount']
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Payment Date & Time",
      cell: (row) => {
        if (row["createdDate"]) {
          // Assuming createdDate is in the format "DD-MM-YYYY HH:MI:SS"
          const [date, time] = row["createdDate"].split(" ");
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },

      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
  ]);

  useEffect(() => {
    getAlltransactions(id);
  }, [currentPage, itemsPerPage, searchKey, range, purchasetype]);

  const handleBack = () => {
    if (Viewtransaction) {
      setTransactioTable(true);
      setViewtransaction(false);
    } else {
      back();
    }
  };

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button className="emptyBtn" onClick={handleBack}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {Viewtransaction ? "View Transaction" : "Transaction"}
            </h5>
          </div>
        </div>

        {/* <div className="position_relative">
          <button
            className="lemonPie_clr MainButton gap12"
            onClick={handleTogglePicker}
          >
            <img className='width20px' src={SelectdateIcon} />
            <span>Select date</span>
          </button>
          {isOpen && (
            <div className="DateRangePickerPosition">
              <DateRangePicker ranges={[range]} onChange={handleSelect} />
              <button
                className="closeDateRangePicker"
                onClick={handleTogglePicker}
              >
                <img src={CloseIcon} alt="CloseIcon" />
              </button>
            </div>
          )}
        </div> */}
      </div>
      {TransactionTable && (
        <>
          {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                All Transactions List
              </h5>
            </div>


          </div> */}
          {isLoading ? (
            <PaginatedTable
              row={transactiondetails}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              isExport={false}
              setRange={setRange}
              range={range}
              isDateRange={true}
              ispurchasetype={true}
              purchasetype={purchasetype}
              setpurchasetype={setpurchasetype}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {Viewtransaction && <ViewTransaction data={transactiondetails} />}
    </div>
  );
};

export default Transactions;
