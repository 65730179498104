import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActivateIcon from "../../assets/images/active_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

import { ActiveCountry } from "../../services/country.service";
import EditCountry from "./Edit-Country";

const ViewCountry = ({ data, back }) => {
  console.log('data::: ', data);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const CategoryUpdate = async (data) => {
    try {
      const payload = {
        id: data?.id,
        status : !data?.active,
      };
      const res = await ActiveCountry(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div className="displayFlex alignItem_center gap12">
        <div>
          <button onClick={() => back(false)} className="emptyBtn">
            <img src={ArrowleftIcon} />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            View Country
          </h5>
        </div>
        </div>
        <div className="displayFlex justifyContent_end gap12">
          <div>
            {/* <button
              className="MainButton neverything_clr gap8"
              type="button"
              onClick={() => back(false)}
            >
              {" "}
              <img src={BackIcon} alt="BackIcon" />
              <span> Back </span>
            </button> */}
          </div>
          <div>
            <button
              className="actionIcon_btn lemonPie_clr customTooltip"
              type="button"
              onClick={() => handleShowedit(data)}
            >
              <img
                className="width20px"
                src={EditIcon}
                alt="EditIcon"
              />
              <span className="custom_tooltiptext">Edit</span>
            </button>
          </div>
          <div>
            <button
              className={
                data?.active
                  ? "actionIcon_btn errorText_clr customTooltip"
                  : "actionIcon_btn UFOGreen_clr customTooltip"
              }
              onClick={() => CategoryUpdate(data)}
            >
              {data?.active ? (
                <img
                  src={DeactivateIcon}
                  alt="deactivate_icon"
                  className="width20px"
                />
              ) : (
                <img
                  src={ActivateIcon}
                  alt="ActivateIcon"
                  className="width20px"
                />
              )}
              <span className="custom_tooltiptext">
                {data?.active ? "Deactivate" : "Activate"}</span>
            </button>
          </div>
        </div>
      </div>

      {CategoryTable && (
        <>
          <div className="tableResponsive">
            <table className="table_detail">
              <tr>
                <th> Name</th>
                <td>{data?.name}</td>
              </tr>
              <tr>
                <th>Currency Name </th>
                <td>{data?.currency_name}</td>
              </tr>
              {/* 
          <tr>
            <th> Currency Code </th>
            <td>{data?.currency_symbol}</td>
          </tr>

          <tr>
            <th>Currency Symbols</th>
            <td>{data?.currency_symbol}</td>
          </tr> */}
              <tr>
                <th>Mobile Code</th>
                <td>{data?.dial}</td>
              </tr>
            </table>
          </div>
        </>
      )}
      {showEdit && <EditCountry data={data} back={back} />}
    </div>
  );
};

export default ViewCountry;
