import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { passwordRegex } from "../../utils/Regex";
import { AdminResetPassword } from "../../services/super-admin.service";
import { BrandResetPassword } from "../../services/merchant.service";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import SaveIcon from "../../assets/images/save_icon.svg";
import Eyehide from "../../assets/images/eye_hide.svg";
import Eyeshow from "../../assets/images/eye_show.svg";
import { useNavigate } from "react-router-dom";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

const ResetPasswordForm = () => {
  const [show, setShow] = useState(false);
  const [oldpasswordVisibilty, setoldpasswordVisbilty] = useState(false);
  const [newpasswordVisibilty, setnewpasswordVisbilty] = useState(false);
  const [ConfirmpasswordVisibilty, setConfirmpasswordVisbilty] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)?.userDetail;

  const id = user?.userType === "admin" ? user?.user_id : user?.brand_id;

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: Yup.object().shape({
        oldPassword: Yup.string().required("Old Password is required"),
        newPassword: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("New Password is required")
          .matches(
            passwordRegex,
            "Password must contain at least one uppercase, one lowercase, one number, and one special character"
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Confirm Password is required"),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          values.id = id;
          const res =
            user?.userType === "admin"
              ? await AdminResetPassword(values)
              : await BrandResetPassword(values);

          if (res?.status === 200 && res?.data?.statusCode === 200) {
            toast.success(res.data.message);
            resetForm();
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            console.error(msg);
          }
          console.log(error.message);
        }
      },
    });
  const back = () => {
     
      navigate('/admin/dashboard');
  };
  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
                <div className="displayFlex alignItem_center gap12">
                    <div>
                        <button className="emptyBtn" onClick={back} >
                            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
                        </button>
                    </div>
                    <div>
                        <h5 className="fontSize20 fontWeight600 blackClr_text">
                            {" Reset Password"}
                        </h5>
                    </div>
                </div>


            </div>
      

      <div className="row">
        <div className="col-md-5">
          <form onSubmit={handleSubmit}>
            <div className="mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Old Password
            </label>
           

           
            <div className=" Groupinput mb_12">
 
              <input
                className="MainInput"
                // type="password"
                type={oldpasswordVisibilty ? "text" : "password"}
                placeholder="Enter Old Password"
                id="oldPassword"
                name="oldPassword"
                value={values.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className="inputgroup_append">
                <span className="Groupinput_text">
                  <button
                    type="button"
                    className="emptyBtn"
                    onClick={() => setoldpasswordVisbilty(!oldpasswordVisibilty)}
                  >
                    {oldpasswordVisibilty ? (
                      <img src={Eyeshow} alt="Eyeshow" />
                    ) : (
                      <img src={Eyehide} alt="Eyehide" />
                    )}
                  </button>
                </span>
              </div>
              
            </div>
            {errors.oldPassword && touched.oldPassword ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.oldPassword}
              </p>
            ) : (
              <></>
            )}
            </div>
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                New Password
              </label>
            <div className=" Groupinput">

              <input
                className="MainInput"
                // type="password"
                type={newpasswordVisibilty ? "text" : "password"}
                placeholder="Enter New Password"
                id="newPassword"
                name="newPassword"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
                    <div className="inputgroup_append">
                <span className="Groupinput_text">
                  <button
                    type="button"
                    className="emptyBtn"
                    onClick={() => setnewpasswordVisbilty(!newpasswordVisibilty)}
                  >
                    {newpasswordVisibilty ? (
                      <img src={Eyeshow} alt="Eyeshow" />
                    ) : (
                      <img src={Eyehide} alt="Eyehide" />
                    )}
                  </button>
                </span>
              </div>
           
            </div>
              {errors.newPassword && touched.newPassword ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.newPassword}
                </p>
              ) : (
                <></>
              )}

            </div>

            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                Confirm Password
              </label>
            <div className=" Groupinput">

              <input
                className="MainInput"
                // type="password"
                type={ConfirmpasswordVisibilty ? "text" : "password"}
                placeholder="Enter Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
                    <div className="inputgroup_append">
                <span className="Groupinput_text">
                  <button
                    type="button"
                    className="emptyBtn"
                    onClick={() => setConfirmpasswordVisbilty(!ConfirmpasswordVisibilty)}
                  >
                    {ConfirmpasswordVisibilty ? (
                      <img src={Eyeshow} alt="Eyeshow" />
                    ) : (
                      <img src={Eyehide} alt="Eyehide" />
                    )}
                  </button>
                </span>
              </div>

            </div>
              {errors.confirmPassword && touched.confirmPassword ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.confirmPassword}
                </p>
              ) : (
                <></>
              )}

            </div>

            {/* ========= Submit Button ========= */}
            <button type="submit" className="MainButton  cyanSky_clr gap8">
              <img className="width20px" src={SaveIcon} alt="SaveIcon" />
              <span>Save</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
