import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
// import DeleteIcon from "../../assets/images/delete_icon.svg";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActivateIcon from "../../assets/images/active_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import EditCategorysetting from "./Edit-Category-setting";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import { CategoryStatusupdate } from "../../services/Category.service";

const ViewCategorysetting = ({ data, back }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const CategoryUpdate = async (data) => {
    try {
      const payload = {
        id: data?.id,
        status: !data?.active_flag,
      };
      const res = await CategoryStatusupdate(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };


  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };
  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button
              className="emptyBtn"
              type="button"
              onClick={() => back(false)}
            >
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              View Category
            </h5>
          </div>
        </div>
        <div className="displayFlex justifyContent_end gap12">
          <div>
            <button
              className="actionIcon_btn lemonPie_clr customTooltip"
              type="button"
              onClick={() => handleShowedit(data)}
            >
              <img className="width20px" src={EditIcon} alt="EditIcon" />
              <span className="custom_tooltiptext">Edit</span>
            </button>
          </div>
          <div>
            <button
              className={
                data?.active_flag
                  ? "actionIcon_btn errorText_clr customTooltip"
                  : "actionIcon_btn UFOGreen_clr customTooltip"
              }
              onClick={() => CategoryUpdate(data)}
            >
              {data?.active_flag ? (
                <img
                  src={DeactivateIcon}
                  alt="deactivate_icon"
                  className="width20px"
                />
              ) : (
                <img
                    src={ActivateIcon} alt="ActivateIcon"
                  className="width20px"
                  
                />
              )}
              <span className="custom_tooltiptext">{data?.active_flag ? "Deactivate" : "Activate"}</span>
              
            </button>
            <div>
        
          </div>
          </div>
        </div>
      </div>

      {CategoryTable && (
        <div className="tableResponsive">
         <table className="table_detail">
        <tr>
          <th> Category Name</th>
          <td>{data?.category_name}</td>
        </tr>
        <tr>
          <th>Category Logo</th>
          <td>
            {data && data.image ? (
              <img src={data.image} alt="Category" width={60} />
            ) : (
              "-"
            )}
          </td>
        </tr>
      </table>
       </div>
      )}

     


         {showEdit && <EditCategorysetting data={data} back={back} />}

      
    </div>
  );
};

export default ViewCategorysetting;
