import Dropdown from "react-bootstrap/Dropdown";
import Adminlogo from "../../assets/images/admin_logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from "../../store/user/user.selector";
import { setCurrentUser } from "../../store/user/user.reducer";



const Header = ({ handleToggleClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser)?.userDetail;


  const handleLogout = () => {
    Cookies.remove('token');
    dispatch(setCurrentUser(''));
    navigate(`/${user?.userType}`);
  };

  return (
    <div className="navbarHeader">
      <div>        <button onClick={handleToggleClick} className="mobileToggle" ><span></span><span></span><span></span></button>
</div>
      <div className="displayFlex alignItem_center gap20">
        <div>
          <Dropdown className="userProfile">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="displayFlex alignItem_center gap12"
            >
              <div>
                <img src={Adminlogo} alt="Adminlogo" />
              </div>
              <div className="userprofileText">{user?.name}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item onClick={() => navigate(`/${user?.userType}/reset-password`)}>Change Password</Dropdown.Item>
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>

              {/* <Dropdown.Item href='#'>Another action</Dropdown.Item>
            <Dropdown.Item href='#'>Something else</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
