import { Navigate, useRoutes } from "react-router-dom";
import SuperAdmin from "./routes/super-admin/super-admin";
import ForgotPassword from "./components/auth/ForgotPassword";
import NotFoundPage from "./components/not_found_page/NotFoundPage";

const App = () => {

  const routes = useRoutes([
    { path: "/", element: <Navigate to="/admin" replace={true} /> },
    { path: "admin/*", element: <SuperAdmin /> },
    { path: "brand/*", element: <SuperAdmin /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "*", element: <NotFoundPage /> },
  ]);
  return <>{routes}</>;
};
// gh


export default App;
