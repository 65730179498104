import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";
import OTPPopup from "../otp/otp.component";
import { superAdminLogin } from "../../services/super-admin.service";
import { brandLogin } from "../../services/merchant.service";
import { useNavigate, useLocation } from "react-router-dom";
import BrandingComponent from "../brand_register/brand_register.component";
import Krtilogo from "../../assets/images/krti_logo_black.svg"
import { setCurrentUser } from "../../store/user/user.reducer";
import Eyehide from "../../assets/images/eye_hide.svg";
import Eyeshow from "../../assets/images/eye_show.svg";
import Modal from 'react-bootstrap/Modal';
import Sayshoplogo from "../../assets/images/sayshop_logo.svg";
import { setRole } from "../../store/role/role.reducer";
import SayshopIcon from "../../assets/images/sayshop_icon.svg";



// import './sign-in-form.styles.scss';

const defaultFormFiled = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userType = location.pathname?.includes('admin') ? "admin" : "brand"

  const [formFiled, setFormField] = useState(defaultFormFiled);
  const [otpPopup, setOtpPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordVisibilty, setPasswordVisibilty] = useState(false)


  const [showPopup, setPopupShow] = useState(false);

  const handlePopupClose = () => setPopupShow(false);
  const handlePopupShow = () => setPopupShow(true);

  const { email, password } = formFiled;

  const changeField = (event) => {
    const { name, value } = event.target;
    setFormField({ ...formFiled, [name]: value });
  };
  const resetForm = () => {
    setFormField(defaultFormFiled);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // const { user } = await signAuthByEmailAndPassword(email, password);
      // console.log(email, password)
      const req = { email, password };
      let res = userType === 'admin' ? await superAdminLogin(req) : await brandLogin(req);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        let token = res.data.data.token
        let decodedToken = {}
        decodedToken['userDetail'] = jwtDecode(token);
        console.log('decodedToken::: ', decodedToken);
        const expirationTime = decodedToken.exp * 1000;
        // const currentTime = Date.now();
        // const timeUntilExpiration = expirationTime - currentTime;
        Cookies.set('token', token, { expires: new Date(expirationTime) });
        dispatch(setCurrentUser(decodedToken));
        dispatch(setRole(decodedToken['userDetail']['role']));
        console.log("SET ROLE", decodedToken['userDetail']['role']);
        resetForm();
        navigate(`/${userType}/dashboard`)
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg)

      }
      console.log(error.message);
    }
  };

  const onClose = () => {
    console.log("closed");
    setOtpPopup(false);
  };




  const brandRegisterOnClose = (cond) => {
    console.log("closed");
    setShow(false);
    if (cond) {
      setOtpPopup(true);

    }
  };


  return (
    // <div className='sign-up-container'>
    //     <h2>Sign-in using email and password</h2>
    //     {/* <span>Sign-in using email and password</span> */}
    //     <form onSubmit={(event) => { handleSubmit(event) }} >
    //         <FormInput required={true} label={'Email'} type='email' name='email' value={email} onChange={changeField} />
    //         <FormInput required={true} label={'password'} type='password' name='password' value={password} onChange={changeField} />
    //         <div className='buttons-container'>
    //             <Button type='submit'>SignIn</Button>
    //         </div>
    //     </form>
    //     <OTPPopup show={otpPopup} onClose={onClose} />

    // </div>
    <div className="accountWrapper">
      {/* <div></div> */}
      <div className="loginFrame">
        <div className="box_image">
    <div>

    </div>

          <div>
            <h2 class="fontSize34 whiteClr_text fontWeight400 mb_24">Enterprise <br/> Rewards & Loyalty Management</h2>
            {/* <h2 class="fontSize40 whiteClr_text fontWeight400 mb_24">Welcome <br /> to <span class="fontWeight700"> SAYSHOP <br /> Platform</span></h2> */}
          {/* <p class="fontsize16 cyanSkyClr_text fontWeight500">Your all-in-one solution for enhancing and managing seamlessly!</p> */}
          </div>
          <div className="textalign_right">
            <p className="tinClr_text fontSize12 fontWeight500">Powered by <button className="emptyBtn" onClick={handlePopupShow}><img src={SayshopIcon} alt="SayshopIcon" /> </button></p>
            
          </div>
          </div>
        <div>
        <img className="Krtilogo" src={Krtilogo} alt="Krti" />

        <h5 className="fontSize20 fontWeight600 blackClr_text textalign_center">Sign in to account</h5>
        <p className="fontSize14 fontWeight400 SteelWoolClr_text textalign_center mb_24">Enter your email & password to login</p>
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <FormInput
            required={true}
            label={"Email Address"}
            placeholder="Email Address"
            type="email"
            name="email"
            value={email}
            onChange={changeField}
          />
          {/* <FormInput
            required={true}
            label={"Password"}
            type="password"
            name="password"
            value={password}
            onChange={changeField}
          /> */}

          <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
            Enter the Password
          </label>
          <div className="Groupinput mb_24">

            <input

              required={true}
              id="password"
              type={passwordVisibilty ? "text" : "password"}
              name="password"
              value={password}
              onChange={changeField}

              placeholder="Enter Password"
            // label={"Email Address"}

            />

            <div className="inputgroup_append">
              <span className="Groupinput_text">
                <button
                  type="button"
                  className="emptyBtn"
                  onClick={() => setPasswordVisibilty(!passwordVisibilty)}
                >

                  {passwordVisibilty ? (
                    <img src={Eyeshow} alt="Eyeshow" />
                  ) : (
                    <img src={Eyehide} alt="Eyehide" />
                  )}
                </button>
              </span>
            </div>
          </div>




          {/* <p className="textalign_right fontSize14 fontWeight500 mb_16 SteelWoolClr_text cursorPointer" onClick={() => navigate('/forgot-password')} >
            Forgot Password ?
          </p> */}
          <div className="buttons-container">
            <div className=" displayFlex alignItem_center gap12">
              <Button type="submit">SignIn</Button>
              {/* <Button type="button" onClick={handleShow} >Sign up</Button> */}

            </div>
            {/* <Button type="submit">SignIn</Button>  <br />
            <Button type="button" onClick={handleShow} >Sign up</Button> */}
          </div>
        </form>
        </div>
       
      </div>
      <OTPPopup show={otpPopup} onClose={onClose} />
      <BrandingComponent show={show} onClose={brandRegisterOnClose} />


      {/* <div className="pbr_24 zIndex999"> */}
        {/* <p className="fontSize14 fontWeight500 SteelWoolClr_text textalign_right">© Copyright 2024 <button onClick={handlePopupShow} className="cyanSkyClr_text TextDecoration_Underline emptyBtn">Sayshop</button>. All Rights
          Reserved </p> */}
        {/* <p className="fontSize14 fontWeight500 whiteClr_text textalign_right">Powered by <button onClick={handlePopupShow} className="cyanSkyClr_text TextDecoration_Underline emptyBtn">Sayshop</button></p> */}
     
        <Modal show={showPopup} centered onHide={handlePopupClose} className="poweredByPopup">
          <Modal.Header closeButton>
            <div></div>
            <div className="Sayshoplogo">
              <img src={Sayshoplogo} alt="Sayshoplogo" />
            </div>
          </Modal.Header>
          <Modal.Body>


            <h5 className="fontSize24 fontWeight500 blackClr_text textalign_center mb_12">
              Enterprise Rewards & Loyalty Management
            </h5>

            <p className="fontSize16 fontWeight500 SteelWoolClr_text textalign_center">Version 8.1.82.2024</p>

          </Modal.Body>
        </Modal>
      {/* </div> */}
    </div>
  );
};

export default SignInForm;
