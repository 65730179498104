import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { rootReducer } from './root-reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';



const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [process.env.NODE_ENV === 'development' && logger].filter(
    Boolean
);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (defaultMiddleware) => defaultMiddleware({
        serializableCheck: false
    }).concat(middleWares)
})