import React from 'react';
// import './authentication.styles.scss'
import SignInForm from '../../../components/sign-in-form/sign-in-form.component';

const Authentication = () => {

    return (
        < >
            <SignInForm />
        </>
    )
}

export default Authentication