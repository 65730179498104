import React, { useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Modal from 'react-bootstrap/Modal';
import Sayshoplogo from "../../assets/images/sayshop_logo.svg";

const Layout = () => {
  const [toggle, setToggle] = useState(false)

  const handleToggleClick = () => {
    setToggle(!toggle)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className={toggle ? " page_wrapper show" : "page_wrapper"}>
      <Sidebar toggle={toggle} />
      <div className="content_wrapper">
        <div>
        <Header handleToggleClick={handleToggleClick} />

        <div className="MainContent">
          <Outlet />
        </div>
        </div>

        <Modal show={show} onHide={handleClose} centered className="poweredByPopup">
          <Modal.Header closeButton>
            <div></div>
            <div className="Sayshoplogo">
              <img src={Sayshoplogo} alt="Sayshoplogo" />
            </div>
          </Modal.Header>
          <Modal.Body>
            

            <h5 className="fontSize24 fontWeight500 blackClr_text textalign_center mb_12">
              Enterprise Rewards & Loyalty Management
          </h5>
            <p className="fontSize16 fontWeight500 SteelWoolClr_text textalign_center">Version 8.1.82.2024</p>


          </Modal.Body>
        </Modal>
        <div className="adminFooter">
          {/* <p className="fontSize16 fontWeight500 SteelWoolClr_text textalign_center">© Copyright 2024 <button onClick={handleShow} className="cyanSkyClr_text TextDecoration_Underline emptyBtn">Sayshop</button>. All Rights
                            Reserved </p> */}

          <p className="fontSize16 fontWeight500 SteelWoolClr_text textalign_right">Powered by <button onClick={handleShow} className="cyanSkyClr_text TextDecoration_Underline emptyBtn">Sayshop</button></p>
        </div>
      </div>
    </div>
  );
};

export default Layout;
