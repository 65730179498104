import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  transaction,
  transactionbyid,
  viewtransactionbyid,
} from "../../services/Bank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import Accordion from "react-bootstrap/Accordion";
// import ViewTransaction from "./ViewTransaction";
import EditLocationForm from "./EditLocationForm";
import ViewIcon from "../../assets/images/view_icon.svg";
import {
  removelocation,
  locationstatus,
} from "../../services/Location.service";

import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";

import EditIcon from "../../assets/images/edit_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { selectRole } from "../../store/role/role.selector";

function ViewLoaction({ brand, back }) {
  console.log("brand::: ", brand);
  const { id } = useParams();
  const [Ediform, setEdiform] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const role = useSelector(selectRole);
  const handleShowedit = (data) => {
    setShowEdit(true);
    setCategoryTable(false);
  };

  const locationStatusUpdate = async (brand) => {
    try {
      const payload = {
        id: brand?.id,
        status: !brand?.active_flag,
      };

      const res = await locationstatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error occurred while updating brand status:", error);
    }
  };

  const removelocationtest = async (id) => {
    try {
      const payload = {
        id: id,
      };
      const res = await removelocation(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
   
  }, []);
  return (
    <>
      <div>
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {showEdit ? "Edit Location Details" : "View Location Details"}
            </h5>
          </div>
          {role == "merchant"
            ? ""
            : !showEdit && (
                <div className="displayFlex justifyContent_end gap12">
                  <div>
                    <button
                      className="MainButton lemonPie_clr gap8"
                      type="button"
                      onClick={() => handleShowedit(brand)}
                    >
                      <img
                        className="width20px"
                        src={EditIcon}
                        alt="EditIcon"
                      />
                      {/* <span>Edit</span> */}
                    </button>
                  </div>
                  <div>
                    <button
                      className={
                        brand?.active_flag
                          ? "actionIcon_btn customTooltip  errorText_clr"
                          : "actionIcon_btn customTooltip  UFOGreen_clr"
                      }
                      onClick={() => locationStatusUpdate(brand)}
                    >
                      {brand?.active_flag ? (
                        <img
                          src={DeactivateIcon}
                          alt="DeactivateIcon"
                          className="width20px"
                        />
                      ) : (
                        <img
                          src={ActiveIcon}
                          alt="ActiveIcon"
                          className="width20px"
                        />
                      )}
                      <span className="custom_tooltiptext">
                        {" "}
                        {brand?.activeFlag === "active" ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      className="actionIcon_btn customTooltip errorText_clr gap8"
                      onClick={() => removelocationtest(brand?.id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        className="width20px"
                      />
                      <span className="custom_tooltiptext">Delete</span>
                    </button>
                  </div>
                </div>
              )}
        </div>

        {CategoryTable && (
          <>
            <div className="tableResponsive">
              <table className="table_detail">
                <tbody>
                  <tr>
                    <th>Contact Name</th>
                    <td>{brand?.contact_name || "-"}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{brand?.email || "-"}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{brand?.location || "-"}</td>
                  </tr>
                  <tr>
                    <th>Latitude</th>
                    <td>{brand?.latitude || "-"}</td>
                  </tr>
                  <tr>
                    <th>Longitude</th>
                    <td>{brand?.longitude || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {showEdit && <EditLocationForm brand={brand} back={back} />}
    </>
  );
}

export default ViewLoaction;
