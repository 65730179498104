import React, { useEffect, useState } from "react";
import { addbrand } from "../../utils/validators/validators";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { registerBrand, Counrtylist } from "../../services/super-admin.service";
import { getCategory, getTimeZoneList } from "../../services/merchant.service";

import CloseIcon from "../../assets/images/close_icon.svg";
import { fileUpload } from "../../services/common.service";
import UploadImg from "../../assets/images/upload_img.svg";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { selectcountry } from "../../store/countrylist/country.selector";
import Select from "react-select";

const fileType = "Merchant";

function CreateBrand({ back }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [Category, setCategory] = useState([]);
  const [timezone, setTimezone] = useState([]);

  const [selectedTimezone, setselectedTimezone] = useState([]);
  console.log("selectedTimezone::: ", selectedTimezone.value);

  const [dialCode, setDialCode] = useState("");

  const handleSelectChange = (selectedOptions) => {
    setFieldValue(selectedOptions);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const Rewardtype = [
    { key: "Open Loop", value: "openLoop" },
    { key: "Closed Loop", value: "closedLoop" },
  ];

  const storetype = [
    { key: "Offline", value: "OFFLINE" },
    { key: "Online", value: "ONLINE" },
    { key: "Both", value: "BOTH" },
  ];

  const handleTypeChange = (e) => {
    setSelectedOptions(e);
    setFieldValue("category_type", e);
  };

  const handleTypeChange2 = (e) => {
    setselectedTimezone(e);
    setFieldValue("time_zone", e);
  };

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const handleTypeChange1 = (bool) => {
    setFieldValue("is_single_store", bool);

    setFieldValue("store_count", 1);
  };
  const handleTypeChangecashpolicy = (bool) => {
    setFieldValue("is_cashback_policy", bool);

    setFieldValue("is_cashback_policy_days", 1);
  };

  const AddNewBrandFormik = useFormik({
    initialValues: {
      name: null,
      website_url: null,
      contact_name: null,
      email: null,
      mobile: null,
      logo: null,
      selectedLogo: null,
      merchant_vat_id: null,
      country: null,
      city: null,
      reward_type: null,
      category_type: null,
      dial_code: null,
      latitude: null,
      longitude: null,
      android_package_name: null,
      ios_package_name: null,
      ios_package_url: null,
      android_package_url: null,
      location: null,
      store_count: 1,
      store_type: null,
      is_single_store: true,
      time_zone: null,
      is_cashback_policy: false,
      is_cashback_policy_days: 1,
    },
    validationSchema: addbrand,
    onSubmit: async (
      {
        name,
        website_url,
        contact_name,
        mobile,
        email,
        logo,
        merchant_vat_id,
        country,
        city,
        reward_type,
        category_type,
        latitude,
        ios_package_name,
        android_package_name,
        ios_package_url,
        android_package_url,
        longitude,
        location,
        store_count,
        store_type,
        time_zone,
        is_single_store,
        is_cashback_policy,
        is_cashback_policy_days,
      },
      { resetForm }
    ) => {
      try {
        const payLoad = {
          name,
          website_url,
          contact_name,
          mobile,
          email,
          brand_logo: logo,
          merchant_vat_id,
          country,
          city,
          reward_type,
          category_type: selectedOptions,
          dial_code: dialCode,
          latitude,
          longitude,
          location,
          ios_package_name,
          android_package_name,
          ios_package_url,
          android_package_url,
          store_count: +store_count,
          store_type,
          is_single_store: values.is_single_store,
          time_zone: selectedTimezone.value,
          is_cashback_policy: values.is_cashback_policy,
          is_cashback_policy_days: +is_cashback_policy_days,
        };

        const res = await registerBrand(payLoad);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          sessionStorage.setItem("userId", res.data.data);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.log(msg);
        }
        console.log(error.message);
        console.log("error::: ", error);
      }
    },
  });

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    setErrors,
  } = AddNewBrandFormik;

  console.log("tes------------------------",AddNewBrandFormik.errors)

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          setFieldValue("logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };


  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
  };

  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();

  // const handleCountryChange = (e) => {
  //   const selectedCountry = e.target.value;
  //   console.log('selectedCountry::: ', selectedCountry);
  //   const country = countries.find((c) => c.name === selectedCountry);
  //   const selectedDialCode = country ? country.dialCode : "";
  //   setDialCode(selectedDialCode);

  // };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;

    setFieldValue("country", selectedCountry);

    const country = countries.find((c) => c.name === selectedCountry);
    const selectedDialCode = country ? country.dialCode : "";

    setDialCode(selectedDialCode);
    setFieldValue("dial_code", selectedDialCode);
  };

  useEffect(() => {
    // getAlloffer()
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          const countries = res.data.data.map((country) => ({
            name: country.name,
            dialCode: country.dial,
          }));
          dispatch(setcountry(countries));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    const getCategorylist = async () => {
      try {
        const res = await getCategory();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          if (res?.data?.data) {
            setCategory(
              res?.data?.data?.map((category) => ({
                label: category.category_name,
                value: category.id,
              }))
            );
          }
          // console.log("res::: ", res);
          // dispatch(setcountry(res?.data?.data));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    const Timezonelist = async () => {
      try {
        const res = await getTimeZoneList();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          if (res?.data?.data) {
            const timezoneOptions = res.data.data.map((item, index) => ({
              value: item.tz,
              id: item.index,

              label: item.tz,
            }));

            setTimezone(timezoneOptions);
          }
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getCountry();
    getCategorylist();
    Timezonelist();
  }, [dispatch]);

  return (
    <div className="whiteFrame">
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween"> */}
      <div className="displayFlex alignItem_center gap12 mb_24">
        <div>
          <button onClick={handleClose} className="emptyBtn">
            {" "}
            <img className="black_filter" src={ArrowleftIcon} alt="backIcon" />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Create Merchant
          </h5>
        </div>
      </div>
      {/* <div>
          <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={handleClose}
          >
            <img src={BackIcon} alt="AddIcon" />
            <span> Back </span>
          </button>
        </div> */}
      {/* </div> */}

      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* =========    Brand Name ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
              htmlFor="fn"
            >
              Merchant Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Merchant Name"
              id="name"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.name && touched.name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= logo ========= */}
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Merchant Logo
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12 ">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Merchant Logo</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Store Type ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Merchant Type
            </label>
            <select
              className="selectMainInput"
              id="store_type"
              placeholder="reward type"
              // id="city"
              name="store_type"
              value={values.store_type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option>---Merchant Type---</option>
              {storetype.map((city, index) => (
                <option key={index} value={city.value}>
                  {city.key}
                </option>
              ))}
            </select>

            {errors.store_type && touched.store_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.store_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Website URL ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Website URL
            </label>
            <input
              className="MainInput"
              type="website_url"
              placeholder="Enter Website URL "
              id="website_url"
              name="website_url"
              value={values.website_url}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.website_url && touched.website_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.website_url}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Contact Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Contact Name"
              id="contact_name"
              name="contact_name"
              value={values.contact_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.contact_name && touched.contact_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.contact_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Email ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="MainInput"
              type="email"
              placeholder="Enter Email"
              id="email"
              name="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.email}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Status ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Country
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              name="country"
              value={values.country}
              onBlur={handleBlur}
              onChange={handleCountryChange}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {errors.country && touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= City ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              City
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter City"
              id="city"
              name="city"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.city && touched.city ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.city}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Location=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Location
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Location"
              id="location"
              name="location"
              value={values.location}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.location && touched.location ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.location}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Time Zone Select ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              TimeZone
            </label>

            <Select
              className="customSelect selectCategory"
              // value={selectedOptions}
              defaultValue={selectedTimezone}
              onChange={handleTypeChange2}
              options={timezone}
              // isMulti={true}
              // id="timezone-select"
            />

            {errors.time_zone && touched.time_zone ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.time_zone}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Mobile
            </label>

            <div className="row">
              <div className="col-md-2 pRzero">
                <div className="MainInputFrame">{dialCode}</div>
              </div>
              <div className="col-md-10">
                <input
                  className="MainInput flex-grow-1"
                  type="text"
                  placeholder="Enter Mobile"
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>

            {errors.mobile && touched.mobile ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.mobile}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= VAT ID ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Vat Id
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Vat Id"
              id="merchant_vat_id"
              name="merchant_vat_id"
              value={values.merchant_vat_id}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.merchant_vat_id && touched.merchant_vat_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.merchant_vat_id}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Multiple select ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Category Select
            </label>

            <Select
              className="customSelect selectCategory"
              value={selectedOptions}
              onChange={handleTypeChange}
              options={Category}
              isMulti={true}
            />

            {errors.category_type && touched.category_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category_type}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Country ========= */}

          {/* ========= Cash Type ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Cash BackType
            </label>
            <select
              className="selectMainInput"
              id="reward_type"
              placeholder="reward type"
              // id="city"
              name="reward_type"
              value={values.reward_type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option>---Cash BackType---</option>
              {Rewardtype.map((city, index) => (
                <option key={index} value={city.value}>
                  {city.key}
                </option>
              ))}
            </select>

            {errors.reward_type && touched.reward_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.reward_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6 mob_mb24">
            <div className="mb_24 ">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="toggleValueInput"
              >
                Store Type
              </label>
              <div className="displayFlex alignItem_center gap12">
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="single_store"
                    name="is_single_store"
                    value={true}
                    checked={values.is_single_store == true}
                    onChange={(e) => handleTypeChange1(true)}
                  />
                  <label htmlFor="single_store">Single Store</label>
                </div>
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="is_multiple_store"
                    name="is_single_store"
                    value={false}
                    checked={values.is_single_store == false}
                    onChange={(e) => handleTypeChange1(false)}
                  />
                  <label htmlFor="is_multiple_store">Mutliple Store</label>
                </div>
              </div>
              {errors.is_single_store && touched.is_single_store ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.is_single_store}
                </p>
              ) : (
                <></>
              )}
            </div>

            {values.is_single_store == false && (
              <div>
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="email"
                >
                  Store Count
                </label>
                <input
                  className="MainInput"
                  type="text"
                  placeholder="Enter Store Count"
                  id="store_count"
                  name="store_count"
                  value={values.store_count}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.store_count && touched.store_count ? (
                  <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                    {errors.store_count}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className="mb_24 col-md-6 mob_mb24">
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="toggleValueInput"
              >
                Cashback return policy
              </label>
              <div className="displayFlex alignItem_center gap12">
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="yes"
                    name="is_cashback_policy"
                    value={true}
                    checked={values.is_cashback_policy == true}
                    onChange={(e) => handleTypeChangecashpolicy(true)}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="no"
                    name="is_cashback_policy"
                    value={false}
                    checked={values.is_cashback_policy == false}
                    onChange={(e) => handleTypeChangecashpolicy(false)}
                  />
                  <label htmlFor="no">NO</label>
                </div>
              </div>
              {errors.is_cashback_policy && touched.is_cashback_policy ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.is_cashback_policy}
                </p>
              ) : (
                <></>
              )}
            </div>

            {values.is_cashback_policy == true && (
              <div>
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="email"
                >
                  Cashback Return policy days
                </label>
                <input
                  className="MainInput"
                  type="text"
                  placeholder="Enter Cashback Return policy days"
                  id="is_cashback_policy_days"
                  name="is_cashback_policy_days"
                  value={values.is_cashback_policy_days}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.is_cashback_policy_days &&
                touched.is_cashback_policy_days ? (
                  <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                    {errors.is_cashback_policy_days}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          {/*===========Latitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Latitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Latitude"
              id="latitude"
              name="latitude"
              value={values.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.latitude && touched.latitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.latitude}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Longitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Longitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Longitude"
              id="longitude"
              name="longitude"
              value={values.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.longitude && touched.longitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.longitude}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========ANDROID PACKAGE NAME=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Android Package Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Android  Package Name"
              id="android_package_name"
              name="android_package_name"
              value={values.android_package_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.android_package_name && touched.android_package_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.android_package_name}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/*===========Android PACKAGE URL=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Android Package Url
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Android Package Url"
              id="android_package_url"
              name="android_package_url"
              value={values.android_package_url}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.android_package_url && touched.android_package_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.android_package_url}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/*===========IOS PACKAGE NAME=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              IOS Package Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter IOS Package Name"
              id="ios_package_name"
              name="ios_package_name"
              value={values.ios_package_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.ios_package_name && touched.ios_package_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.ios_package_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========iOS PACKAGE URL=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              IOS Package Url
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter IOS Package Url"
              id="ios_package_url"
              name="ios_package_url"
              value={values.ios_package_url}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.ios_package_url && touched.ios_package_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.ios_package_url}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap8">
          <img className="width20px" src={CreateIcon} alt="CreateIcon" />
          <span> Save Draft</span>
        </button>
      </form>
    </div>
  );
}

export default CreateBrand;
