import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import EditIcon from "../../assets/images/edit_icon.svg";
import { getCustomerCreditLimit } from "../../services/Global.service";
import PaginatedTable from "../common/PaginatedTable";
import { Counrtylist } from "../../services/super-admin.service";
import { useDispatch } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../assets/images/close_icon.svg";
import EditConfigSetting from "./Edit-Global-setting";
import { formatCurrency } from "../../utils/helpers/helper";

function GlobalSetting() {
  const [Creditlimits, setCreditlimit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [status, setStatus] = useState("");
  // pagination
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  // country
  const dispatch = useDispatch();

  const getAllCreditlimits = async () => {
    try {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
        statusfilter: status || "all",
      };
      const resp = await getCustomerCreditLimit(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setCreditlimit(resp?.data?.data?.data);
        setTotalCount(resp?.data?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCreditlimits();
  }, [currentPage, itemsPerPage, searchKey]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          const countries = res.data.data.map((country) => ({
            name: country.name,
            dialCode: country.dial,
          }));
          dispatch(setcountry(countries));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getCountry();
  }, []);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
  };

  const [column] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <>
          <div>
            <button className="emptyBtn" onClick={() => handleEdit(row)}>
              <img className="CyanSky_filter" src={EditIcon} alt="EditIcon" />
            </button>
          </div>
        </>
      ),

      hidden: false,
      // width: "120",
      // align: "center",
      id: 1,
    },
    {
      heading: () => "Country",
      cell: (row) => (row["name"] ? row["name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => "Currency",
      cell: (row) => (row["currency"] ? row["currency"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Minimum Withdrawal",
      cell: (row) =>
        row["min_withdraw"]
          ? formatCurrency(row["min_withdraw"] || 0, row["currency"])
          : "-",
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "Maximum Withdrawal",
      cell: (row) =>
        row["max_withdraw"]
          ? formatCurrency(row["max_withdraw"] || 0, row["currency"])
          : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Status",
      cell: (row) => {
        console.log("row::: ", row);
        const status = row["active_flag"];
        console.log("status::: ", status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return (
          <span
            className={
              row?.active_flag
                ? "fontWeight600 UFOGreen_text"
                : "fontWeight600 errorClr_text"
            }
            style={style}
          >
            {displayText}
          </span>
        );
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  const closeModal = () => {
    getAllCreditlimits();
    setIsEdit(false);
  };

  return (
    <div>
      <div className="whiteFrame">
        <div className="mb_24">
          <h4 className="fontSize20 fontWeight600 blackClr_text">
            Global Settings
          </h4>
        </div>
        <PaginatedTable
          row={Creditlimits}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isSearch={true}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          maxPageNumberLimit={maxPageNumberLimit}
          setMaxPageNumberLimit={setMaxPageNumberLimit}
          minPageNumberLimit={minPageNumberLimit}
          setMinPageNumberLimit={setMinPageNumberLimit}
          isExport={false}
          isDateRange={false}
          status={status}
          setStatus={setStatus}
          isstatus={true}
        />
        <Modal
          centered
          show={isEdit}
          onHide={() => setIsEdit(false)}
          backdrop="static"
          className="addCoach_popup"
        >
          <Modal.Body>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h2 className="fontSize18 fontWeight600 blackClr_text  ">
                  {`Edit ${editdata?.name} Config`}
                </h2>
              </div>
              <div>
                <button className="emptyBtn" onClick={() => setIsEdit(false)}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
              </div>
            </div>
            <EditConfigSetting data={editdata} back={closeModal} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default GlobalSetting;
