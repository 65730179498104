import React from "react";
import ReactApexChart from "react-apexcharts";

const DashboardChart = ({ options, series, chartType, title }) => {
  return (
    <div>
      <ReactApexChart
        options={{
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          markers: {
            size: 0,
          },
          colors: ["#00b1b1"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Product Trends by Month",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: options,
          },
          yaxis: {
            title: {
              text: `No Of ${title}`,
            },
          },
          theme: {
            palette: "palette10",
          },
        }}
        series={[
          {
            name: `${title}`,
            data: series,
          },
        ]}
        type={chartType}
        height={350}
      />
    </div>
  );
};

export default DashboardChart;
