import { KrtiPayService } from './api'

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const superAdminLogin = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/user/signin`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const adminDashboard = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/getDashboard`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const brandDashboardCount = async (brand_id) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/brandCount/${brand_id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const DashboardChartDetails = async (chartType, payload) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/dashboard/getAll/${chartType}`, payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const dashBoardChart = async (chart, req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/dashboard/getAll/${chart}`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const brandChart = async (brand_id, req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/dashboard/getBrandOffers/${brand_id}`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const registerBrand = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/createBrand`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const verifyOtp = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/verifyOtp`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getBrandList = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate,statusfilter } = requestBody
    console.log('statusfilter::: ', statusfilter);
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getAllUsers?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}&statusfilter=${statusfilter}`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getDropDownList = async (requestBody) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getUsersDropDownList`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}




export const getCustomerList = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate,statusfilter } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/getAllUsers?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}&statusfilter=${statusfilter}`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getAdminUserList = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate,statusfilter } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/getAllAdminList?offset=${offset}&limit=${limit}&search=${search}&statusfilter=${statusfilter}`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

// add admin

export const createAdmin = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/user/registerUser`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}






export const updatebrand = async (body) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/merchant-management/update-brand`, body)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}














export const AdminResetPassword = async (req) => {
    try {
        let payload = {
            old_password: req?.oldPassword,
            new_password: req?.newPassword
        }
        return await KrtiPayService.post(`${krtiWebUrl}/user/reset_password/${req.id}`, payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const Counrtylist = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/get-countries-list`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getlogs = async (req,requestBody) => {
    const { offset, limit,status, user_id, brand_id, search, startDate, endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/user/get-all-logs?offset=${offset}&limit=${limit}&search=${search}`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}





