import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { verifyOtp } from '../../services/super-admin.service';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const OTPPopup = ({ show, onClose }) => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    }
    return () => clearTimeout(countdown);
  }, [timer]);

  const handleResend = () => {
    // Reset timer to 30 seconds
    setTimer(30);
    // Implement resend logic here (e.g., send a new OTP)
    console.log("Resending OTP...");
  };

  const handleOTPChange = (event) => {
    const inputOTP = event.target.value;
    if (/^[0-9]{0,6}$/.test(+inputOTP)) {
      setOTP(inputOTP ?? "");
      setError("");
    } else {
      setError("OTP must be a 6-digit number");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle OTP submission here
    console.log("submitted");
    if (otp.length === 6) {
      const userId = sessionStorage.getItem("userId") || ""; // Corrected sessionStorage key

      let payload = {
        user_id: userId, // Corrected variable name
        otp: +otp
      };

      try {
        let res = await verifyOtp(payload);
        // You can make an API call to verify OTP or handle it locally
        // For demonstration, let's just log it
        console.log(res); // Assuming `verifyOtp` returns some response
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          sessionStorage.setItem('token', res.data.data);
          navigate('/')
          setOTP("")
          onClose();
        } else {
          toast.error(res?.message || res?.data?.message);
        }

      } catch (error) {
        // Handle error if verification fails
        console.error("Verification failed:", error);
      }
    } else {
      setError("OTP must be a 6-digit number");
    }
  };


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {/* <Modal.Title>OTP Verification</Modal.Title> */}

        <div>
          <h5 className=" fontSize18 fontWeight600 blackClr_text mb_8">
            OTP Verification
          </h5>
          <p className="SteelWoolClr_text fontSize14 fontWeight500">
            Enter the OTP Send to this Email Address{" "}
            {/* <span className="blackClr_text">
                {" "}
                {getEmailFromSession() ?? ""}{" "}
              </span> */}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="otp"
            >
              OTP
            </label>
            <input
              type="text"
              className="MainInput"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOTPChange}
            />
            {error && <div className="error">{error}</div>}
            {/* <button type="submit">Submit</button> */}
          </div>

          <div className="displayFlex alignItem_center gap12">
            <div>
              <button className="MainButton cyanSky_clr width100per" type="submit">
                Verify
              </button>
            </div>
            <div>
              <button
                className="MainButton cyanSky_clr width100per"
                onClick={handleResend}
                disabled={timer !== 0}
              >
                Resend OTP
              </button>
            </div>
            <div className="resend">
              {timer !== 0 && <span>Resend in {timer} seconds</span>}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default OTPPopup;
