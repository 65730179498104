import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCommentsList, cashbackPriority } from "../../services/cashback.service";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import { getCategory, MerchantDetailByID } from "../../services/merchant.service";
// import { useParams } from 'react-router-dom';

import * as Yup from "yup";
import { useFormik } from "formik";

function CashBackPriority({ back, brand }) {

  const { id } = useParams();


  const [data, setdata] = useState("");
  const [cashbackType, setCashbackType] = useState(data.cashback_priority);


  const user = useSelector(selectCurrentUser)?.userDetail?.user_id;
 


  const handleTypeChange = (e) => {
   
    setCashbackType(e.target.value);
  };


  const getMerchantDetail = async () => {
    try {
      const res = await MerchantDetailByID(id);
     
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setdata(res?.data?.data?.getAllMerchant?.[0]);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };








  const CreateCashBack = async (e) => {
   
    e.preventDefault();
    try {
      const payLoad = {
        brand_id: id,
        priority_type: cashbackType,

      };

      const res = await cashbackPriority(payLoad);
    

      if (res?.status === 200 && res?.data?.statusCode === 200) {

        toast.success(res.data.message);
        back()
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.log(msg);
      }
      console.log(error.message);
      console.log("error::: ", error);
    }
  };








  useEffect(() => {
    setCashbackType(data.cashback_priority);
    getMerchantDetail(id)

  }, [id, data.cashback_priority]);
  return (
    <div className="disabled">
      {/* <h1>cash prooioru------------</h1> */}
      <form onSubmit={CreateCashBack}>
        <div className="princessElleClr_frame mb_24">
          <p className="fontSize20 fontWeight500 blackClr_text mb_8">
            Choose an option to be given the cashback priority </p>
          <p className="fontSize16 fontWeight400 SteelWoolClr_text mb_24">
            Note: Choose which option to given the  <span className="blue-light">cashback priority</span>
          </p>
          <div className="mb_34 row">
            <div className="prefer_method col-md-3 mob_mb24">
              <input
                type="radio"
                id="maximunCashBack"
                name="priority_type"
                value="MaximumCashback"
                onChange={handleTypeChange}
                checked={cashbackType === "MaximumCashback"}
                required
              />
              <label
                htmlFor="maximunCashBack"
                className="fontSize14 fontWeight500 blackClr_text display_block"
              >
                Maximun CashBack
              </label>

              {""}
            </div>
            <div className="prefer_method  col-md-3">
              <input
                disabled={true}
                type="radio"
                id="minimunCashBack"
                name="priority_type"
                value="MinimumCashback"
                onChange={handleTypeChange}
                checked={cashbackType === "MinimumCashback"}
              />
              <label
                htmlFor="minimunCashBack"
                className="fontSize14 fontWeight500 blackClr_text display_block"
              >
                Minimum CashBack
              </label>

            </div>
          </div>
          <div>
            <div>
              <button disabled={true} type="submit" className="MainButton disabled cyanSky_clr ">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* <div className="Table_Wrapper tableResponsive">
        <table className="mb_24">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Subject</th>
              <th>Comments</th>
              <th>Created by</th>
              <th>Created date</th>
            </tr>
          </thead>
          <tbody>
            {commentList?.map((comment, i) => {
              return (
                <tr>
                  <td> {i + 1}</td>
                  <td> {comment?.subject}</td>
                  <td> {comment?.comments}</td>
                  <td> {comment?.created_date}</td>
                  <td> {comment?.updated_by}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}

export default CashBackPriority;
