import React, { useEffect, useState } from "react";

import {
  updateCampaignStatus,
  deleteCampaign,
  getcampaignlist
} from "../../services/campaign.service";

import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import EditIcon from "../../assets/images/edit_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import EditCampaign from "./EditCampaign";
import ConfirmPopup from "../common/confirmation component/ConfirmPopup";
import { useSelector } from "react-redux";
import { selectRole } from "../../store/role/role.selector";
import PaginatedTable from "../common/PaginatedTable";
import { convertToISOString } from "../../services/common.service";


function ViewCampaign({ campaign, campainlist, brand ,back }) {
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const [activePopup, setActivePopup] = useState(null);
  const role = useSelector(selectRole);
  const [campaignlist, setcampianlist] = useState(null);
  console.log('campaignlist::: ', campaignlist);
  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });
  // const [activePopup, setActivePopup] = useState(null);
  // const role = useSelector(selectRole);

  const confirm = {
    status: 'status',
    delete: 'delete'
  }

  useEffect(() => {
  
    
    const getAllcampilist = async () => {
      const brandid= campaign?.brand_id
      console.log('brandid::: ', brandid);
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;
      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
          startDate: convertToISOString(range.startDate),
          endDate: convertToISOString(range.endDate),
        };
        const resp = await getcampaignlist(brandid, requestBody);
  
        if (resp?.status === 200 && resp?.data?.statusCode === 200) {
          setcampianlist(resp?.data?.data);
          setTotalCount(resp?.data?.count);
        } else {
          toast.error(resp?.message || resp?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getAllcampilist()
  }, [currentPage, itemsPerPage, searchKey, setSearchKey,range]);


  const campaignStatusUpdate = async (campaign) => {
    try {
      const payload = {
        id: campaign?.id,
        status: !campaign?.active_flag,
      };

      const res = await updateCampaignStatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) { }
  };



  const campaignDelete = async (id) => {
    try {
      const payload = {
        id: id,
      };

      const res = await deleteCampaign(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);

        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) { }
  };

  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };

  const confirmPopFunction = () => {
    if (confirm?.status === activePopup) campaignStatusUpdate(campaign);
    if (confirm?.delete === activePopup) campaignDelete(campaign?.id);
    setActivePopup(null);
  }

  const [column, setColumn] = useState([
    // {
    //   heading: () => "Code",
    //   cell: (row) => (row["code"] ? row["code"] : "-"),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 2,
    // },

    {
      heading: () => "Customer Name/ Customer Mobile",
      cell: (row) => (row["user_name"] ? row["user_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },

      {
      heading: () => "Email",
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },


    {
      heading: () => "Used By",
      cell: (row) => {
        if (row["updated_date"]) {
          const dateObj = new Date(row["updated_date"]);
          
          // Format the date as DD-MM-YYYY
          const date = dateObj.toLocaleDateString('en-GB').split('/').reverse().join('-');
          
          // Format the time as HH:MI:SS
          const time = dateObj.toTimeString().split(' ')[0];
          
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },

    
    // {
    //   heading: () => "Expired Date",
    //   cell: (row) => {
    //     if (row["expiration_time"]) {
    //       const dateObj = new Date(row["expiration_time"]);
    //       const date = dateObj.toLocaleDateString(); 
    //       const time = dateObj.toLocaleTimeString(); 
    //       return `${date} ${time}`;
    //     } else {
    //       return "-";
    //     }
    //   },
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 6,
    // },

    // {
    //   heading: () => "Redeemed",
    //   cell: (row) => (
    //     <span style={{ color: row["redeemed"] ? "green" : "red" }}>
    //       {row["redeemed"] ? "Redeemed" : "Not-Redeemed"}
    //     </span>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 8,
    // },
  ]);

  return (
    <>
      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.status === activePopup}
      />

      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.delete === activePopup}
      />
      <h2 className="fontSize20 blackClr_text mb_16">
        <span className="fontWeight400">Merchant Name - </span>
        <span className="fontWeight600">{campaign?.brand_name}</span>
      </h2>
      <div className="whiteFrame">




        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap BorderBottom_LavenderSyrup pb_16">
          <div className="displayFlex alignItem_center  gap12">
            <div>
              <button onClick={() => back(false)} className="emptyBtn">
                <img src={ArrowleftIcon} />
              </button>
            </div>
            <div>
              <h2 className="fontSize20 fontWeight600 blackClr_text">
              
                {showEdit ? "Edit Campaign Details" : "Campaign Details"}
              </h2>
            </div>
          </div>
          {/* <div>
                    <h5 className="fontSize20 fontWeight600 blackClr_text">View Campaign</h5>
                </div> */}
          {!showEdit && (
            <div className="displayFlex justifyContent_end gap12">
            <div>
              <button
                className="actionIcon_btn lemonPie_clr customTooltip"
                type="button"
                onClick={() => handleShowedit(campaign)}
              >
                <img className="width20px" src={EditIcon} alt="EditIcon" />
                <span className="custom_tooltiptext">Edit</span>
              </button>
            </div>
            {/* <div>
                        <button
                            className="MainButton neverything_clr gap8"
                            type="button"
                            onClick={() => back(false)}
                        >
                            <img src={BackIcon} alt="BackIcon" />
                            <span> Back </span>
                        </button>
                    </div> */}
            <div>
                {role == "admin" || role == "superadmin" &&
                  <button
                    className={
                      campaign?.active_flag
                        ? "actionIcon_btn errorText_clr customTooltip"
                        : "actionIcon_btn UFOGreen_clr customTooltip"
                    }
                    onClick={() => setActivePopup(confirm?.status)}
                  >
                    {campaign?.active_flag ? (
                      <img
                        src={DeactivateIcon}
                        alt="deactivate_icon"
                        className="width20px"
                      />
                    ) : (
                      <img src={ActiveIcon} alt="ActiveIcon" className="width20px" />
                    )}
                    <span className="custom_tooltiptext"> {campaign?.active_flag ? "Deactivate" : "Activate"}</span>
                  </button>
                }

            </div>
            <div>
              <button
                className="actionIcon_btn errorText_clr customTooltip"
                onClick={() => setActivePopup(confirm?.delete)}
              >
                <img src={DeleteIcon} alt="DeleteIcon" className="width20px" />
                <span className="custom_tooltiptext">Delete</span>
              </button>
            </div>
          </div>

          )}
          
        </div>

        {CategoryTable && (

          <>

            <div className="row mb_24">
              <div className="col-md-2 mob_mb24">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  Campaign Logo
                </label>
                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={campaign?.campaign_logo || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  Banner Image
                </label>

                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={campaign?.banner_image || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>

              </div>
            </div>






            <div className="tableResponsive">
              <table className="table_detail">
                <tr>
                  <th> Campaign Name</th>
                  <td className="textWrap_Wrap">{campaign?.campaign_name}</td>
                </tr>
                <tr>
                  <th>Merchant Name</th>
                  <td className="textWrap_Wrap">{campaign?.brand_name}</td>
                </tr>

                <tr>
                  <th> Start Date</th>
                  <td>{campaign?.start_Date}</td>
                </tr>

                <tr>
                  <th>End Date</th>
                  <td>{campaign?.end_Date}</td>
                </tr>
                <tr>
                  <th>Offer Type</th>
                  <td>{campaign?.offer_type}</td>
                </tr>

                <tr>
                  <th>Value</th>
                  <td>
                    {campaign?.offer_type === "Percentage"
                      ? `${campaign?.offer_value}%`
                      : formatCurrency(campaign?.offer_value)}
                  </td>
                </tr>
                <tr>
                  <th> Promo Code</th>
                  <td>{campaign?.campaign_promo}</td>
                </tr>

                <tr>
                  <th>Coupon Count</th>
                  <td>{campaign?.coupon_count}</td>
                </tr>
                <tr>
                  <th>Available Count</th>
                  <td>{campaign?.available_coupon}</td>
                </tr>
                <tr>
                  <th>Minimum Spend</th>
                  <td>{formatCurrency(campaign?.min_spend)}</td>
                </tr>

                {campaign?.offer_type === "Percentage" && (
                  <tr>
                    <th>Upper Limit</th>
                    <td>{formatCurrency(campaign?.upper_limit)}</td>
                  </tr>
                )}
              </table>
            </div>

            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h5 className="fontSize20 fontWeight600 blackClr_text">
                  Campaign  List
                </h5>
              </div>
            </div>

            {isLoading ? (
              <PaginatedTable
                row={campaignlist}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isSearch={true}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                 setRange={setRange}
                 range={range}
                isExport={false}
                isDateRange={true}
              />
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}
          </>
        )}
        {showEdit && <EditCampaign data={campaign} brand={brand} back={back} />}
      </div>
    </>
  );
}

export default ViewCampaign;
