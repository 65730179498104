
import { createSlice } from '@reduxjs/toolkit';


export const Country_INITIAL_STATE = {
    countries: [],
};



export const CountrySlice = createSlice({
    name: 'Country',
    initialState: Country_INITIAL_STATE,
    reducers: {
        setcountry(state, action) {
            state.countries = action.payload
        }
    }
})


export const { setcountry } = CountrySlice.actions;
export const CountryReducer = CountrySlice.reducer;