import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getCustomerCreditLimit = async (req) => {
    try {
        const { offset, limit, search } = req;
        return await KrtiPayService.get(`${krtiWebUrl}/configuration-management/customer-credit-limit?offset=${offset}&limit=${limit}&search=${search}`,)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const PostCustomerCreditLimit = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/configuration-management/customer-credit-limit`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CategoryStatusupdate = async (req) => {
    // const {i} = payload
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/category/statusUpdate/${req?.id}/${req?.status}/
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}   