import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getProduct = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id, search, startDate, endDate,statusfilter } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/product/product-list/${id}?offset=${offset}&limit=${limit}&search=${search}&statusfilter=${statusfilter}`,requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CreateNewProduct = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/product/add-product
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const EditProductDetail = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/product/update-product
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const updateProductStatus = async (payload) => {

    const { product_id,status } = payload;
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/product/deactive-product/${product_id}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const deleteProductById = async (payload) => {
   
    const { id  } = payload
   
    
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/product/delete-product/${id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getProductdroplist = async (requestBody) => {
    const { offset, limit, user_id, brand_id, search, startDate, endDate, } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/product/product-dropdown-list/${brand_id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}




