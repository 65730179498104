import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { viewtransactionbyid } from "../../services/Bank.service";
import { formatCurrency } from "../../utils/helpers/helper";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { useNavigate, useParams } from "react-router-dom";


function ViewCustomertransaction({data}) {
 
  let id = data?.[0]?.master_transaction_id;
  const navigate = useNavigate();
  const {merchant_name  } = useParams();
  const [viewtransactiondetails, setviewtransactiond] = useState(null);

  const getAlltransactions = async (id) => {
    try {
      const requestBody = {
        master_id: id,
      };
      const resp = await viewtransactionbyid(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setviewtransactiond(resp?.data?.data);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAlltransactions(id);
  }, [merchant_name]);
  return (
    <>
      {/* <h2 className="fontSize20 blackClr_text mb_16">
            <span className="fontWeight400">Merchant Name - </span>
            <span className="fontWeight500">{merchant_name}</span>
          </h2> */}
        
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center gap12">
        {/* <div>
          <button onClick={() => handleClick()} className="emptyBtn">
            <img src={ArrowleftIcon} />
          </button>
        </div> */}

        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            View Transaction
          </h5>

          {/* <h2 className="fontSize20 blackClr_text mb_16">
                        <span className="fontWeight400">Customer Name - </span>
                        <span className="fontWeight600">{viewtransactiondetails?.[0]?.merchant_name}</span>
                    </h2> */}

        
        </div>
      </div>

      <div className="Table_Wrapper tableResponsive">
        <table className="mb_24">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Pay Reference</th>
              <th>Payment Type</th>
              <th>Payment Status</th>
              <th>Amount</th>
              <th>Payment Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {viewtransactiondetails?.map((tdetails, i) => {
              return (
                <tr key={tdetails?.id}>
                  <td> {i + 1}</td>
                  <td> {tdetails?.pay_refernce ?? "-"}</td>

                  <td>{tdetails?.transaction_method ?? "-"}</td>
                  <td> {tdetails?.transaction_status ?? "-"}</td>

                  <td>
                    {tdetails?.amount !== null && tdetails?.amount !== undefined
                      ? formatCurrency(tdetails.amount)
                      : "-"}
                  </td>
                  <td> {tdetails?.paymentDate ?? "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
}

export default ViewCustomertransaction;
