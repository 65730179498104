// ConfirmPopup.js
import React from 'react';
import './ConfirmPopup.css'; // Import the CSS for styling

const ConfirmPopup = ({ message, onConfirm, onCancel, isOpen }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup">
                <h5 className="fontSize20 fontWeight600 blackClr_text mb_34">{message}</h5>
                <div className="displayFlex justifyContent_end">
                    <div>  <button onClick={onConfirm} className="popup-button confirm">Confirm</button></div>
                    <div> <button onClick={onCancel} className="popup-button cancel">Cancel</button></div>
                  
                   
                </div>
            </div>
        </div>
    );
};

export default ConfirmPopup;
