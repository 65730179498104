import { combineReducers } from '@reduxjs/toolkit';

import { userReducer } from './user/user.reducer';
import { CountryReducer } from './countrylist/country.reducer';
import { roleReducer } from './role/role.reducer';


export const rootReducer = combineReducers({
    user: userReducer,
    country: CountryReducer,
    role: roleReducer
});