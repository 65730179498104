import React from 'react';
import './NotFoundPage.css'; // Import CSS file for styling

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <h1 className="not-found-title">404 - Page Not Found</h1>
            <p className="not-found-text">Sorry, the page you are looking for does not exist.</p>
            {/* You can add more content, like a link back to the home page */}
        </div>
    );
};

export default NotFoundPage;
