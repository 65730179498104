import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCommentsList, postComments } from '../../services/merchant.service';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

const Comments = ({ back }) => {

    const [subject, setSubject] = useState('');
    const [comment, setComment] = useState('');

    const [commentList, setCommentList] = useState([])

    const { id } = useParams();
    const user = useSelector(selectCurrentUser)?.userDetail?.user_id;

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };



    const fetchCommentList = async () => {
        try {
            const res = await getCommentsList(id);
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                setCommentList(res?.data?.data);

            } else {
                // toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                // alert(msg);
                console.error(msg)
            }
            console.log(error.message);

        }
    }

    const createComments = async (e) => {
        e.preventDefault();
        try {
            const payLoad = {
                "subject": subject,
                "comments": comment,
                "brand_id": id,
                "updated_by": user
            }

            const res = await postComments(payLoad);

            if (res?.status === 200 && res?.data?.statusCode === 200) {
                setSubject('');
                setComment('');
                fetchCommentList();
                toast.success(res.data.message);
            } else {
                toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                // alert(msg);
                console.log(msg);
            }
            console.log(error.message);
            console.log("error::: ", error);
        }

    }

    useEffect(() => { fetchCommentList() }, [])

    return (
        <div>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
                <div className="displayFlex alignItem_center gap12">
                    <div>
                        <button className="emptyBtn" onClick={back}>
                            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
                        </button>
                    </div>
                    <div>
                        <h5 className="fontSize20 fontWeight600 blackClr_text">
                            {"Comments"}
                        </h5>
                    </div>
                </div>


            </div>
            <form onSubmit={createComments}>

                <div className='princessElleClr_frame mb_24'>
                    <div className='mb_24'>
                        <label htmlFor="subject" className='fontSize14 fontWeight500 blackClr_text display_block mb_12'>Subject</label>
                        <input className='MainInput'
                            type="text"
                            id="subject"
                            value={subject}
                            onChange={handleSubjectChange}
                            required
                        />
                    </div>
                    <div className='mb_24'>
                        <label htmlFor="comment" className='fontSize14 fontWeight500 blackClr_text display_block mb_12'>Comment</label>
                        <textarea className='MainTextarea' rows={6}
                            id="comment"
                            value={comment}
                            onChange={handleCommentChange}
                            required
                        />
                    </div>
                    <div className='displayFlex alignItem_center'>
                        <div>
                            <button type="submit" className='MainButton lemonPie_clr'>Submit</button>

                        </div>
                    </div>

                </div>
            </form>
            <div className="Table_Wrapper tableResponsive">
                <table className="mb_24">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Subject</th>
                            <th>Comments</th>
                            <th>Created date</th>
                            <th>Created by</th>



                        </tr>
                    </thead>
                    <tbody>
                        {commentList?.map((comment, i) => {
                            return (
                                <tr >
                                    <td> {i + 1}</td>
                                    <td className="textWrap_Wrap"> {comment?.subject}</td>
                                    <td className="textWrap_Wrap"> {comment?.comments}</td>
                                    <td> {comment?.created_Date}</td>
                                    <td> {comment?.Updated_by}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Comments