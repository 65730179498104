import React from "react";
import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <div>
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
    </div>
  );
};

export default Toast;
