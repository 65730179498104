import React, { useState } from "react";
import ViewBrandDetail from "./ViewBrandDetails";
import Transactions from "./transcation";
import BankDetails from "./bank-details";
import DocumentCenter from "./document-center";
import Comments from "./comments";
import BackIcon from "../../assets/images/back_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import TransactionsIcon from "../../assets/images/transactions_icon.svg";
import CashBack from "../../assets/images/cashback_icon.svg";
import setting from "../../assets/images/setting_icon.svg";
import BanckAccIcon from "../../assets/images/banckAcc_icon.svg";
import CommentsIcon from "../../assets/images/comments_icon.svg";
import DocumentCenterIcon from "../../assets/images/document_center_icon.svg";
import BrandDetailsIcon from "../../assets/images/brandDetails_icon.svg";
import location_outline from "../../assets/images/location_outline.svg";
import Product_icon from "../../assets/images/product-variant_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import EditBrandForm from "./EditBrandForm";
import CashBackManagement from "./cashback-management";
import LocationManagement from "./loaction-management";
import ProductManagement from "./product-management";


import CashBackPriority from "./CashBackPriority";
import Editcashbackrule from "./Editcashbackrule";
import Settingmanagement from "./Setting-management";
import Vouchersetting from "./voucher-setting";
// import EditIcon from "../../assets/images/edit_icon.svg";s
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import DetailIcon from "../../assets/images/detail_icon.svg";
import ApiSecret from "../Api_secretkey/ApiSecret";
import { useSelector } from "react-redux";
import { selectRole } from "../../store/role/role.selector";
import { selectCurrentUser } from "../../store/user/user.selector";



const BrandDetail = () => {

  const navigate = useNavigate();
  const tabs = {
    detail: "Merchant Details",
    edit: "Edit Merchant",
    transcation: "Transaction",
    bankDetails: "Bank Accounts",
    documentCenter: "Document Center",
    comments: "Comments",
    cashBack: "CashBack Management",
    Checkout_setting: "Checkout Setting",
    Api_setting: "API Setting",
    product_add: "Products Management",

    Location: "Location",
    Setting_merchant: "Setting",
    voucher_setting: "Voucher Setting"
  };

  const [activetab, setActiveTab] = useState(tabs.detail);
  const [prevTab, setPrevTab] = useState(null);

  const [editVoucherSetting, setEditVoucherSetting] = useState(false);
  const [merchant, setMerchant] = useState(null);
  console.log('merchant::: ', merchant);
  const role = useSelector(selectRole);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  const handleClick = () => {
    if (activetab !== tabs.detail) {
      return setActiveTab(tabs.detail)
    } else {
      if (role == "admin" || role == "superadmin") {
        navigate('/admin/brand');
      } else {
        navigate(location.pathname, { replace: true });
      }
    }
  };

  const ActivateTab = (tab) => {

    setPrevTab(activetab);
    setActiveTab(tab)

  }

  return (
    <div>
      {merchant && (
        <h2 className="fontSize20 blackClr_text mb_16">
          <span className="fontWeight400">Merchant Name - </span>
          <span className="fontWeight500">{merchant.name}</span>
        </h2>
      )}
      <ul className="horizontalTablist">
        <li>
          <button onClick={() => ActivateTab(tabs.detail)} className={activetab === tabs.detail ? 'active' : ''} >
            <img src={BrandDetailsIcon} alt="BrandDetailsIcon" />
            Merchant Details
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.bankDetails)} className={activetab === tabs.bankDetails ? 'active' : ''}>
            <img src={BanckAccIcon} alt="BanckAccIcon" />
            <span>Bank Account</span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.documentCenter)} className={activetab === tabs.documentCenter ? 'active' : ''}>
            <img src={DocumentCenterIcon} alt="DocumentCenterIcon" />
            <span>Document Center</span>
          </button>
        </li>

        <li>
          <button onClick={() => ActivateTab(tabs.cashBack)} className={activetab === tabs.cashBack ? 'active' : ''}>
            <img src={CashBack} alt="TransactionsIcon" />
            <span>Cashback </span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.transcation)} className={activetab === tabs.transcation ? 'active' : ''}>
            <img src={TransactionsIcon} alt="TransactionsIcon" />
            <span>Transaction</span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.comments)} className={activetab === tabs.comments ? 'active' : ''}>
            <img src={CommentsIcon} alt="CommentsIcon" />
            <span>Comments</span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.Api_setting)} className={activetab === tabs.Api_setting ? 'active' : ''} >
            <img src={setting} alt="TransactionsIcon" />
            <span>API Setting</span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.product_add)} className={activetab === tabs.product_add ? 'active' : ''} >
            <img src={Product_icon} alt="Product_icon" />
            <span>Products</span>
          </button>
        </li>
        {merchant?.is_single_store === null || merchant?.is_single_store === false ?(
              <li>
              <button onClick={() => ActivateTab(tabs.Location)} className={activetab === tabs.Location ? 'active' : ''}>
                <img src={location_outline} alt="location_outline" />
                <span>Location</span>
              </button>
            </li>

         ): null}
    
        <li>
          <button onClick={() => ActivateTab(tabs.Setting_merchant)}disabled={true} className="opacity_ZeroFour disabled">
            <img src={CommentsIcon} alt="CommentsIcon" />
            <span>Setting</span>
          </button>
        </li>
     
        <li>
          <button onClick={() => ActivateTab(tabs.voucher_setting)} disabled={true} className="opacity_ZeroFour disabled" >
            <img src={CommentsIcon} alt="CommentsIcon" />
            <span>Voucher Setting</span>
          </button>
        </li>
        <li>
          <button onClick={() => ActivateTab(tabs.Checkout_setting)} disabled={true} className="opacity_ZeroFour disabled">
            <img src={setting} alt="TransactionsIcon" />
            <span>Checkout Setting</span>
          </button>
        </li>

      </ul>
      <br />

      <div className="whiteFrame">
        {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
          <div className="displayFlex alignItem_center gap12">
            <div>
              <button className="emptyBtn" onClick={handleClick}>
                <img src={ArrowleftIcon} alt="ArrowleftIcon" />
              </button>
            </div>
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {activetab}
              </h5>
            </div>
          </div>

          <div className="displayFlex alignItem_center gap12">










            {activetab === tabs.voucher_setting && (
              <div>
                <button className='actionIcon_btn lemonPie_clr customTooltip'
                  type="button"
                  onClick={() => setEditVoucherSetting(!editVoucherSetting)}
                >
                  {!editVoucherSetting ? (
                    <img src={EditIcon} className="width20px" alt="EditIcon" />
                  ) : (
                    <img src={DetailIcon} alt="DetailIcon" className="width20px" />
                  )}
                  <span className="custom_tooltiptext">
                    {!editVoucherSetting ? "Edit" : "Detail"} </span>
                </button>
              </div>
            )}



          </div>
        </div> */}
        {activetab === tabs.detail && <ViewBrandDetail back={handleClick} setMerchant={setMerchant} />}
        {activetab === tabs.transcation && <Transactions back={handleClick} />}
        {activetab === tabs.bankDetails && (
          <BankDetails back={handleClick} />
        )}
        {activetab === tabs.documentCenter && <DocumentCenter back={handleClick} />}
        {activetab === tabs.comments && <Comments back={handleClick} />}
        {activetab === tabs.Api_setting && <ApiSecret back={handleClick} />}
        {activetab === tabs.Location && <LocationManagement backFunc={handleClick} countdata={merchant} />}
        {activetab === tabs.product_add && <ProductManagement backFunc={handleClick} />}

        {activetab === tabs.cashBack && <CashBackManagement backFunc={handleClick} />}
        {activetab === tabs.Setting_merchant && <Settingmanagement backFunc={handleClick} />}
        {activetab === tabs.voucher_setting &&
          <Vouchersetting editVoucherSetting={editVoucherSetting} setEditVoucherSetting={setEditVoucherSetting} />}
      </div>
    </div>
  );
};

export default BrandDetail;