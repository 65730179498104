import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../store/user/user.selector";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import { CreateNewVoucher } from "../../services/voucher.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import Select from "react-select";

import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

const fileType = "Voucher";
const CreateSupportQuery = ({ brand = [], back }) => {
  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };
  const [selectedMerchant, setSelectedMerchant] = useState("");

  const [Category, setCategory] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState([]);


  const user = useSelector(selectCurrentUser)?.userDetail;
  const id = user?.userType === "brand" ? user?.brand_id : "";
  const today = new Date().toISOString().split("T")[0];

  // form
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      VoucherName: "",
      VoucherCount: "",
      brand_id: id,
      startDate: "",
      endDate: "",
      offer_type: "Value",
      offer_value: "",
      voucher_value: "",
      min_spend: 0,
      upper_limit: 0,
      logo: null,
      banner: null,
      selectedLogo: null,
      privacyPolicy: "",
      termsAndCondition: "",
      stepsToRedeem: "",
      category_id:""
    },
    validationSchema: Yup.object({
      VoucherName: Yup.string()
        .max(50, "Voucher Name must be at most 50 characters")
        .required("Voucher Name is required"),
      logo: Yup.string().required("logo is required"),
      banner: Yup.string().required("Banner is required"),
      VoucherCount: Yup.string()
        .required("Voucher Count is required")
        .matches(/^[1-9][0-9]*$/, "Voucher Count must be a number"),
      brand_id: Yup.string().required("Merchant is required"),
      voucher_value: Yup.string().required("Voucher value is required"),
      startDate: Yup.string().required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          test: function (value, { createError }) {
            const isNumeric = /^[0-9]+$/.test(value);
            if (isNumeric) {
              return true;
            }
          },
        })
        .required("Value is required"),
      upper_limit: Yup.string().test({
        name: "upper_limit",
        exclusive: false,
        message: "Upper limit must be a number and less than min spend",
        test: function (value, { parent }) {
          const isNumeric = /^[0-9]+$/.test(value);
          if (parent.offer_type === "Value") {
            return true;
          }
          if (isNumeric) {
            if (parent.offer_type === "Percentage") {
              return (
                parseInt(value, 10) > 0 &&
                parseInt(value, 10) < parent.min_spend
              );
            } else {
              return true; // Reset error for non-"Percentage" offer types
            }
          } else {
            return false; // Reset error for non-numeric values
          }
        },
      }),
      privacyPolicy: Yup.string()
        .max(300, "Privacy Policy  must be at most 300 characters")
        .required("Privacy Policyis required"),
      termsAndCondition: Yup.string()
        .max(300, "Terms and Condition  must be at most 300 characters")
        .required("Terms and Condition is required"),
      stepsToRedeem: Yup.string()
        .max(300, "Steps To Redeem  must be at most 300 characters")
        .required("Steps To Redeem required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
          VoucherCount: +values.VoucherCount,
          min_spend: +values.min_spend,
          upper_limit: +values?.upper_limit,
          voucher_value: +values?.voucher_value,
          discount_value:
            values?.offer_type === "Percentage"
              ? +values?.upper_limit
              : +values?.offer_value,
          coupon_type: "VOUCHER",
          voucher_logo: values?.logo,
          banner_image: values?.banner,
          category_id :+selectedOptions
        };

        const res = await CreateNewVoucher(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

    const handleTypeChange1 = (e) => {
    setSelectedOptions(e);
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleMerchantChange = (event) => {
  
    const { value } = event.target;
   
    setSelectedMerchant(value);
    handleChange(event); 
  };

  const handleTypeChangemerchantcategory = (e) => {
     setSelectedOptions(e.target.value);
   };


  const getCategorylist = async (brand_id) => {
    try {
      
      const res = await GetMerchantCategory(brand_id);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        if (res?.data?.data) {
          setCategory(
            res?.data?.data?.map((category) => ({
              label: category.category_name,
              value: category.id,
            }))
          );
        }
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
      }
      console.log(error.message);
    }
  };
  useEffect(() => {
  
    if (selectedMerchant) {
      
      const getCategorylist = async () => {
        try {
          const res = await GetMerchantCategory(selectedMerchant);
          if (res?.status === 200 && res?.data?.statusCode === 200) {
            if (res?.data?.data) {
              setCategory(
                res?.data?.data?.map((category) => ({
                  label: category.category_name,
                  value: category.category_id,
                }))
              );
            }
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            // alert(msg);
            console.error(msg);
          }
          console.log(error.message);
        }
      };

      getCategorylist(selectedMerchant);
    }
  }, [selectedMerchant]);

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center gap12">
        <div>
          <button className="emptyBtn" type="button" onClick={handleClose}>
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Create Voucher
          </h5>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Voucher Logo
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Voucher Logo</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Voucher Banner
            </label>
            {!values?.banner && (
              <label htmlFor="banner" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Voucher banner</span>
                <input
                  id="banner"
                  type="file"
                  name="banner"
                  value={values.banner}
                  accept="image/*" // Accept all image file types
                  onChange={(e) => handleFileChange(e, "banner")}
                  hidden
                />
              </label>
            )}

            {values?.banner && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.banner}
                  width={100}
                  height={100}
                  alt="selected Banner"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => removeLogo("banner")}
                  />
                </div>
              </div>
            )}

            {!values.banner && touched.banner ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.banner}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Voucher Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Voucher Name "
              id="VoucherName"
              name="VoucherName"
              value={values.VoucherName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.VoucherName && touched.VoucherName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.VoucherName}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Voucher Count
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Voucher Count "
              id="VoucherCount"
              name="VoucherCount"
              value={values.VoucherCount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.VoucherCount && touched.VoucherCount ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.VoucherCount}
              </p>
            ) : (
              <></>
            )}
          </div>

          {!id && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="brand_id"
              >
                Merchant
              </label>
              <select
                className="selectMainInput"
                id="brand_id"
                name="brand_id"
                value={values.brand_id}
                onBlur={handleBlur}
                onChange={handleMerchantChange}
              >
                <option value="">Select Merchant</option>
                {brand.map((count) => (
                  <option key={count.id} value={count.id}>
                    {count.name}
                  </option>
                ))}
              </select>
              {errors.brand_id && touched.brand_id ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.brand_id}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

{!id && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="category_id "
              >
                Category Select
              </label>
              <select
                className="selectMainInput"
                id="category_id "
                name="category_id "
                value={selectedOptions}
                onBlur={handleBlur}
                onChange={handleTypeChangemerchantcategory}
              >
                <option value="">Select Category</option>
                {Category.map((count) => (
                  
                  <option key={count.value} value={count.value}>
                    {count.label}
                  </option>
                  
                ))}
              </select>
              {errors.category_id  && touched.category_id  ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.category_id }
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Start Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="startDate"
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.startDate}
              min={today}
            />
            {errors.startDate && touched.startDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.startDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              End Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="endDate"
              name="endDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.endDate}
              min={values.startDate} // Set minimum date to the selected start date
              // max={values.endDate} // Set maximum date to the end of the current month
            />
            {errors.endDate && touched.endDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.endDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="toggleValueInput"
            >
              Offer type
            </label>
            <div className="displayFlex alignItem_center gap12">
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_value"
                  name="offer_type"
                  value="Value"
                  checked={values.offer_type === "Value"}
                  onChange={(e) => handleTypeChange(e)}
                  // readOnly={true}
                  disabled={true}
                />
                <label htmlFor="offer_value">Value</label>
              </div>
              {/* <div className='prefer_method'>
                            <input
                                type="radio"
                                id="offer_percentage"
                                name="offer_type"
                                value='Percentage'
                                checked={values.offer_type === "Percentage"}
                                onChange={(e) => handleTypeChange(e)}
                            />
                            <label htmlFor="offer_percentage">Percentage</label>
                        </div> */}
            </div>
            {errors.offer_type && touched.offer_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Vouchers Offer Value
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Offer Value"
              id="offer_value"
              name="offer_value"
              value={values.offer_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.offer_value && touched.offer_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_value}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Vouchers Value
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="voucher_value"
              name="voucher_value"
              value={values.voucher_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.voucher_value && touched.voucher_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.voucher_value}
              </p>
            ) : (
              <></>
            )}
          </div>

          {values.offer_type === "Percentage" && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Upper limit
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Value"
                id="upper_limit"
                name="upper_limit"
                value={values.upper_limit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.upper_limit && touched.upper_limit ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.upper_limit}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}


          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Privacy Policy
            </label>
            <textarea
              className="MainTextarea"
              placeholder="Privacy Policy"
              id="privacyPolicy"
              name="privacyPolicy"
              value={values.privacyPolicy}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea>
            {errors.privacyPolicy && touched.privacyPolicy ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.privacyPolicy}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Terms And Condition
            </label>
            <textarea
              className="MainTextarea"
              placeholder="Terms And Condition"
              id="termsAndCondition"
              name="termsAndCondition"
              value={values.termsAndCondition}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea>
            {errors.termsAndCondition && touched.termsAndCondition ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.termsAndCondition}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Steps To Redeem
            </label>
            <textarea
              className="MainTextarea"
              placeholder="Steps To Redeem"
              id="stepsToRedeem"
              name="stepsToRedeem"
              value={values.stepsToRedeem}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea>
            {errors.stepsToRedeem && touched.stepsToRedeem ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.stepsToRedeem}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>Save Draft</span>
        </button>
      </form>
    </div>
  );
};

export default CreateSupportQuery;
