import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  transaction,
  transactionbyid,
  viewtransactionbyid,
} from "../../services/Bank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import Accordion from "react-bootstrap/Accordion";
// import ViewTransaction from "./ViewTransaction";
import Editbank from "../branding/edit-bank";
import ViewIcon from "../../assets/images/view_icon.svg";
import {
  getCashbackRules,
  updateCashBackRules,
  removeCashBackRules,
  ActiveCashBackRules
} from "../../services/cashback.service";


import { addBrandBank, getBrandBankList, bankMasterList, setPrimaryAccount, removeBank } from "../../services/Bank.service";

import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";


import EditIcon from "../../assets/images/edit_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { selectRole } from "../../store/role/role.selector";

function Viewbank({ data,brand, getBankList,back }) {
  // console.log('back::: ', back);
  // console.log('data::: ', data);
  // console.log('getBankList::: ', getBankList);
  // console.log('brand::: ', brand);
  
 
  const { id } = useParams();
  const [Ediform, setEdiform] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const role = useSelector(selectRole);

  const [Viewbanklist, setViewbanklist] = useState(true);

  const [brandTable, setBrandTable] = useState(false);

  const [Viewsachback, Setviewcashback] = useState();

  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };



  

  // const getAllcashbackrules = async (id) => {
  //   try {
  //     const resp = await getCashbackRules(id);

  //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
  //       Setviewcashback(resp?.data?.data);
  //     } else {
  //       toast.error(resp?.message || resp?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();

  //       console.error(msg);
  //     }
  //     console.log(error.message);
  //   }
  // };

    // const cashbackStatusUpdate = async (brand) => {
    
    //   try {
    //     const payload = {
    //       rules_id: brand?.id,
    //       status: !brand?.active_flag,
    //     };

    //     const res = await ActiveCashBackRules(payload);

    //     if (res?.status === 200 && res?.data?.statusCode === 200) {
         
    //       toast.success(res?.data?.message);
    //       back();
    //     } else {
    //       toast.error(res?.message || res?.data?.message);
    //     }
    //   } catch (error) {
    //     console.error("Error occurred while updating brand status:", error);
    //   }
    // };

    const removeAcc = async (accId) => {
      const payload = {
          id : id,
          account_id:accId,
          create_type:"Merchant"
        
        };
      try {
          const res = await removeBank(payload);
          if (res?.status === 200 && res?.data?.statusCode === 200) {
              toast.success(res?.data?.message || res?.message);
              // getBankList(id)
              back()
          } else {
              toast.error(res?.message || res?.data?.message);
          }
      } catch (error) {
          if (error?.message) {
              const msg = error?.message?.split(":")?.[1]?.trim();
              // alert(msg);
              console.error(msg)
          }
          console.log(error.message);

      }

  }


  useEffect(() => {
    // getAllcashbackrules(id);
  }, []);
  return (
    <>

      <div>
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
            {showEdit ? "Edit Bank Details" : "View Bank Details"}
            </h5>
          </div>
          {role == "merchant" ? "" : !showEdit && (
               <div className="displayFlex justifyContent_end gap12">
               <div>
                 <button
                   className="MainButton lemonPie_clr gap8"
                   type="button"
                   onClick={() => handleShowedit(brand)}
                 >
                   <img className="width20px" src={EditIcon} alt="EditIcon" />
                   {/* <span>Edit</span> */}
                 </button>
               </div>
               {/* <div>
                 <button
                   className={
                     brand?.active_flag
                       ? "actionIcon_btn customTooltip  errorText_clr"
                       : "actionIcon_btn customTooltip  UFOGreen_clr"
                   }
                   onClick={() => cashbackStatusUpdate(brand)}
                 >
                   {brand?.active_flag ? (
                     <img
                       src={DeactivateIcon}
                       alt="DeactivateIcon"
                       className="width20px"
                     />
                   ) : (
                     <img
                       src={ActiveIcon}
                       alt="ActiveIcon"
                       className="width20px"
                     />
                   )}
                   <span className="custom_tooltiptext">
                     {" "}
                     {brand?.activeFlag === "active"
                       ? "Active"
                       : "Inactive"}
                   </span>
                 </button>
               </div> */}
               <div>
                 <button
                   className="actionIcon_btn customTooltip errorText_clr gap8"
                   onClick={() => removeAcc(data?.id)}
                 >
                   <img src={DeleteIcon} alt="DeleteIcon" className="width20px" />
                   <span className="custom_tooltiptext">Delete</span>
                 </button>
               </div>
             </div>

          )}
       
        </div>

        {CategoryTable && (
          <>
            <div className="tableResponsive">
              <table className="table_detail">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{data?.name || "-"}</td>
                  </tr>
                  <tr>
                    <th>Bank Name </th>
                    <td>{data?.branch_name || "-"}</td>
                  </tr>
                  <tr>
                    <th>Account Number</th>
                    <td>{data?.account_number || "-"}</td>
                  </tr>
                  <tr>
                    <th>Street Address </th>
                    <td>{data?.street_address || "-"}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{data?.city || "-"}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>{data?.state || "-"}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{data?.country || "-"}</td>
                  </tr>
                  <tr>
                    <th>IBAN</th>
                    <td>{data?.iban_number || "-"}</td>
                  </tr>
                  <tr>
                    <th>Swift Code</th>
                    <td>{data?.swift_code || "-"}</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {showEdit && <Editbank editdata={data} back={back} />}
    </>
  );
}

export default Viewbank;
