import { KrtiPayService } from './api'

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const brandLogin = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/signin`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const registerBrand = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/createBrand`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const verifyOtp = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/verifyOtp`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getbrand = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search, startDate,endDate  } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getAllUsers/?offset=${offset}&limit=${limit}`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getCategory = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/get-category`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}




export const addoffer = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/offer-mangement/Add-Offer`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const getoffer = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/offer-mangement`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const updateoffer = async (body) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/offer-mangement/update-Offer`, body)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const BrandResetPassword = async (req) => {
    try {
        let payload = {
            old_password: req?.oldPassword,
            new_password: req?.newPassword
        }
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/reset_password/${req.id}`, payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const updateBrandStatus = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/activeBrand/${req?.id}/${req?.status}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const updatepopularStatus = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/activePromotion
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const Counrtylist = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/get-countries-list`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const addBrandBank = async (req, id) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/addMerchantBank/${id}`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getBrandBankList = async (id) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getMerchantBank/${id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const bankMasterList = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/bank-master`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const setPrimaryAccount = async (merchantId, accID) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/primary-card/${merchantId}/${accID}`, {})
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}
export const removeBank = async (merchantId, accID) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/removeBankAccount/${merchantId}/${accID}`, {})
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}
export const getCommentsList = async (brandId) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/comment-section/fetch-comments/${brandId}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const postComments = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/comment-section/make-comment`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const Merchantlist = async (req) => {

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/Merchant/${req}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const MerchantDetailByID = async (merchantID) => {

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/Merchant/${merchantID}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const seceretByID = async (merchantID) => {

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/Secret/${merchantID}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const changeSecret = async (merchantID,payload) => {

    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/UpdateSecret/${merchantID}
        `,payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const GetFileTypes = async (userType) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/document-vault/get-types/${userType}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const DocumentSubmit = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/document-vault/upload-document`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const GetDocuments = async (brand_id) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/document-vault/get-uploaded-document/${brand_id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getmerchantvariable = async (merchantID) => {

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-variable/Merchant/${merchantID}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const Postmerchantvariable = async (req) => {

    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-variable/merchant-variable
        `,req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getTimeZoneList = async (id) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/time-zones`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


