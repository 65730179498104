import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { passwordRegex } from "../../utils/Regex";
import { AdminResetPassword } from "../../services/super-admin.service";
import {
  BrandResetPassword,
  seceretByID,
  changeSecret,
} from "../../services/merchant.service";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import SaveIcon from "../../assets/images/save_icon.svg";
import Eyehide from "../../assets/images/eye_hide.svg";
import Eyeshow from "../../assets/images/eye_show.svg";
import { useParams } from "react-router-dom";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { useNavigate } from "react-router-dom";

const ApiSecret = ({ back }) => {
  const [show, setShow] = useState(false);
  const [oldpasswordVisibilty, setoldpasswordVisbilty] = useState(false);

  const [data, setData] = useState({});
  console.log("data::: ", data?.merchant_secret);
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)?.userDetail;
  const [keyGenerated, setKeyGenerated] = useState(false);
  const { id } = useParams();

  const client_id = id;

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        old_secret: data?.merchant_secret,
        // new_secret: "",
      },
      // validationSchema: Yup.object().shape({
      //   old_secret: Yup.string().required("API Secret Key is required"),
      // }),

      onSubmit: async (values, { resetForm }) => {
        console.log("values::: ", values);
        try {
          const payload = {
            id: values.id,
            old_secret: data?.merchant_secret,
            new_secret: "string",
            log_id: user.user_id,
          };

          const res = await changeSecret(id, payload);

          if (res?.status === 200 && res?.data?.statusCode === 200) {
            toast.success(res.data.message);

            resetForm();

            setKeyGenerated(true);
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            console.error(msg);
          }
          console.log(error.message);
        }
      },
    });

  const getMerchantSecretKey = async () => {
    try {
      const res = await seceretByID(id);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setData(res?.data?.data?.getAllMerchant?.[0]);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      getMerchantSecretKey();
      setKeyGenerated(false);
    }
  }, [id, keyGenerated]);

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button className="emptyBtn" onClick={back}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {"API Setting"}
            </h5>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-5">
          <form onSubmit={handleSubmit}>
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                Client Secret Key
              </label>

              <div className=" Groupinput mb_12">
                <input
                  className="MainInput"
                  id="client_id"
                  name="client_id"
                  value={client_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              {errors.client_id && touched.client_id ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.client_id}
                </p>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </div> */}

      <div className="row">
        <div className="col-md-5">
          <form onSubmit={handleSubmit}>
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                API Secret Key
              </label>

              <div className=" Groupinput mb_12">
                <input
                  className="MainInput"
                  // type="password"
                  type={oldpasswordVisibilty ? "text" : "password"}
                  // placeholder="Enter API Secret Key"
                  id="oldPassword"
                  name="oldPassword"
                  value={data?.merchant_secret}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="inputgroup_append">
                  <span className="Groupinput_text">
                    <button
                      type="button"
                      className="emptyBtn"
                      onClick={() =>
                        setoldpasswordVisbilty(!oldpasswordVisibilty)
                      }
                    >
                      {oldpasswordVisibilty ? (
                        <img src={Eyeshow} alt="Eyeshow" />
                      ) : (
                        <img src={Eyehide} alt="Eyehide" />
                      )}
                    </button>
                  </span>
                </div>
              </div>
              {errors.oldPassword && touched.oldPassword ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.oldPassword}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= Submit Button ========= */}
            <button type="submit" className="MainButton  cyanSky_clr gap8">
              <img className="width20px" src={SaveIcon} alt="SaveIcon" />
              <span>Generate New Key </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApiSecret;
