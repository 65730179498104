
// import { } from './form-input.style.scss'
import React, { useState } from 'react';

import Eyehide from "../../assets/images/eye_hide.svg";
import Eyeshow from "../../assets/images/eye_show.svg";


const FormInput = ({ label,type, ...otherProps }) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };
    return (
        <div className='mb_24'>
        {label && (
            <label className={`${otherProps.value.length ? 'shrink' : ''} fontSize14 fontWeight500 blackClr_text display_block mb_8`}>
                {label}
            </label>
        )}

        <div className="input-container">
            <input
                className="MainInput"
                {...otherProps}
                type={passwordVisibility ? "text" : type}
            />
            {type === 'password' && (
                <img
                    className="eye_icon"
                    src={passwordVisibility ? Eyehide : Eyeshow}
                    alt={passwordVisibility ? "Hide" : "Show"}
                    onClick={togglePasswordVisibility}
                />
            )}
        </div>
    </div>
);
    
}

export default FormInput
