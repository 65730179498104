import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getCountrylist = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search, startDate,endDate,statusfilter  } = requestBody
    console.log('statusfilter::: ', statusfilter);
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/country-management/fetch-countries?offset=${offset}&limit=${limit}&search=${search}&statusfilter=${statusfilter}`,requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const postCountry= async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/country-management/country`,req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}




export const ActiveCountry = async (req) => {
    // const {i} = payload
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/country-management/statusUpdate/${req?.id}/${req?.status}/
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

