import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getDocumentlist = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search, startDate,endDate,statusfilter  } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/document/fetch-document/?offset=${offset}&limit=${limit}&search=${search}&statusfilter=${statusfilter}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CreateDocument = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/document/add-update-document
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const DocumentStatusupdate = async (req) => {
    // const {i} = payload
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/document/statusUpdate/${req?.id}/${req?.status}/
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}