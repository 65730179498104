import React, { useEffect, useState } from "react";
import { AuthPost } from "../../services/http-client";
// import { SET_PLAYER_COACH_COUNTS } from "../../redux/slices/dashboard-overview-slice";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import OpenlinkIcon from "../../assets/images/openlink_icon.svg";
import GrapharrowIcon from "../../assets/images/grapharrow_icon.svg";
import DashboardChart from "../../components/chart/DashboardChart";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseIcon from "../../assets/images/close_icon.svg";
import { adminDashboard, brandChart, brandDashboardCount, DashboardChartDetails } from "../../services/super-admin.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import SelectdateIcon from "../../assets/images/selectdate_icon.svg";
import { selectRole } from "../../store/role/role.selector";


const Dashboard = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [isChart, setIsChart] = useState(false);
  const [count, setCount] = useState({
    brands: 0,
    offers: 0,
    users: 0,
    transactions: 0,
  });

  const user = useSelector(selectCurrentUser);
  const role = useSelector(selectRole);
  console.log('role::: ', role);

  const userType = user?.userDetail?.userType;

  const [range, setRange] = useState({
    startDate: null,
    endDate: new Date(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    console.log(ranges["range1"]);
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };

  useEffect(() => {
    isChart && fetchAllRecords()    
  }, [isChart, range]);

  const getDashboardCount = async (props) => {
    try {
      const resp = await adminDashboard();
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setCount({
          offers: resp?.data?.data?.getAllOffers || 0,
          brands: resp?.data?.data?.getAllBrands || 0,
          transactions: resp?.data?.data?.getAllTransaction || 0,
          users: resp?.data?.data?.getAllUser || 0,
        });
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };
  const getBrandDashboardCount = async () => {
    try {
      const resp = await brandDashboardCount(user?.userDetail?.brand_id);
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setCount({
          offers: resp?.data?.data?.getAllOffers || 0,
          transactions: resp?.data?.data?.getAllTransaction || 0,
        });
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };

  const fetchAllRecords = async (props) => {
    try {

      const payload = {
        "startDate": range['startDate'],
        "endDate": range['endDate'],
        "isAll": false,
        "brand_id": user?.userDetail?.brand_id 
      }
      const chartType = isChart === 'campaign' ? 'offers' : isChart
      const resp = await DashboardChartDetails(chartType, payload);
      if (resp?.data?.statusCode === 200) {
        // setIsOpen(false)
        if (resp.data.data.getAllRecords) {
          setOptions(resp.data.data.getAllRecords["agg_dates"]);
          setChartSeries(resp.data.data.getAllRecords["agg_count"]);
        }
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };


  const getBrandChart = async () => {
    try {
      const payload = {
        "startDate": range['startDate'],
        "endDate": range['endDate'],
        "isAll": false
      }
      const resp = await brandChart(user?.userDetail?.brand_id, payload);
      if (resp?.data?.statusCode === 200) {
        if (resp.data.data.getAllRecords) {
          setOptions(resp.data.data.getAllRecords["agg_dates"]);
          setChartSeries(resp.data.data.getAllRecords["agg_count"]);
        }
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };



  //   try {
  //     const resp = await AuthGet("superadmin/overview-counts");
  //     if (resp.statusCode === 200) {
  //       dispatch(SET_PLAYER_COACH_COUNTS(resp.data));
  //     }
  //   } catch (error) {
  //     console.log("error::: ", error);
  //   }
  // };

  // const fetchPlayerCoachesCount = async () => {
  //   try {
  //     const resp = await AuthGet("superadmin/overview-counts");
  //     if (resp.statusCode === 200) {
  //       dispatch(SET_PLAYER_COACH_COUNTS(resp.data));
  //     }
  //   } catch (error) {
  //     console.log("error::: ", error);
  //   }
  // };

  useEffect(() => {
    role === 'admin' || role === "superadmin" ? getDashboardCount() :
      (role === 'merchant' && getBrandDashboardCount())
  }, []);

  return (
    <div>
      <div className="row mb_34">
        {
          (role === 'admin' || role === 'superadmin') && <div className="col-lg-3 mob_mb24">
            <div
              className="dashboard_item  cursorPointer"
              onClick={() => {
                setIsChart("merchants");
                setRange({
                  startDate: null,
                  endDate: new Date()
                })
              }}
            >
              <p className="SteelWoolClr_text fontSize16 fontWeight500">
                Merchants
              </p>
              <h2 className="fontSize40 fontWeight600 blackClr_text">
                {" "}
                {count["brands"]}
              </h2>
              <div className="displayFlex alignItem_center gap12">
                <div>
                  <img src={GrapharrowIcon} alt="OpenlinkIcon" />
                </div>

                <div className="ml_auto">
                  <button
                    className="Openpagelink"
                    onClick={() => navigate("/admin/brand")}
                  >
                    <img src={OpenlinkIcon} alt="OpenlinkIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        {
          (role === 'admin' || role === 'superadmin') && <div className="col-lg-3 mob_mb24">
            <div
              className="dashboard_item cursorPointer"
              onClick={() => {
                setIsChart("customers");
                setRange({
                  startDate: null,
                  endDate: new Date()
                })
              }}
            >
              <p className="SteelWoolClr_text fontSize16 fontWeight500">
                Customers
              </p>
              <h2 className="fontSize40 fontWeight600 blackClr_text">
                {count["users"]}
              </h2>
              <div className="displayFlex alignItem_center gap12">
                <div>
                  <img src={GrapharrowIcon} alt="OpenlinkIcon" />
                </div>

                <div className="ml_auto">
                  <button
                    className="Openpagelink"
                    onClick={() => navigate("/admin/customer")}
                  >
                    <img src={OpenlinkIcon} alt="OpenlinkIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        {
          (role === 'admin' || role === 'superadmin' || role === 'merchant') && <div className="col-lg-3 mob_mb24">
            <div
              className="dashboard_item cursorPointer"
              onClick={() => {
                setIsChart("transactions");
                setRange({
                  startDate: null,
                  endDate: new Date()
                })
              }}
            >
              <p className="SteelWoolClr_text fontSize16 fontWeight500">
                Transactions
              </p>
              <h2 className="fontSize40 fontWeight600 blackClr_text">
                {count["transactions"]}
              </h2>
              <div className="displayFlex alignItem_center gap12">
                <div>
                  <img src={GrapharrowIcon} alt="OpenlinkIcon" />
                </div>

                <div className="ml_auto">
                  <button
                    className="Openpagelink"
                  onClick={() => navigate("/admin/transaction")}
                  >
                    <img src={OpenlinkIcon} alt="OpenlinkIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        }


        {(role === 'admin' || role === 'superadmin' || role === 'merchant') && <div className="col-lg-3 mob_mb24">
          <div
            className="dashboard_item cursorPointer"
            onClick={() => {
              setIsChart("campaigns");
              setRange({
                startDate: null,
                endDate: new Date()
              })
            }}
          >
            <p className="SteelWoolClr_text fontSize16 fontWeight500">
              Campaigns 
            </p>
            <h2 className="fontSize40 fontWeight600 blackClr_text">
              {count["offers"]}
            </h2>
            <div className="displayFlex alignItem_center gap12">
              <div>
                <img src={GrapharrowIcon} alt="OpenlinkIcon" />
              </div>

              <div className="ml_auto">
                <button
                  className="Openpagelink"
                  onClick={() => navigate(`/${userType}/campaign`)}
                >
                  <img src={OpenlinkIcon} alt="OpenlinkIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>}
      </div>
      <div className="row">
        <div className="col-lg-12">
          {isChart && (
            <div className="whiteFrame">
              <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
                <div></div>
                <div>
                  <h5 className="fontSize24 fontWeight600 blackClr_text textalign_center">
                    {isChart[0]?.toUpperCase() +
                      isChart?.substring(1) +
                      " Mangement"} </h5>
                </div>
                <div className="position_relative">

                {isChart && (
                  <>
                    <button
                      className="lemonPie_clr MainButton gap12"
                      onClick={handleTogglePicker}
                    >
                      <img className='width20px' src={SelectdateIcon} />
                      <span> Select date</span>
                    </button>
                  </>
                )}

                {isOpen && (
                    <div className="DateRangePickerPosition">
                      <DateRangePicker ranges={[range]} onChange={handleSelect} />
                      <button
                        className="closeDateRangePicker"
                        onClick={handleTogglePicker}
                      >
                        <img src={CloseIcon} alt="CloseIcon" />
                      </button>
                    </div>
                )}
                </div>
              </div>
             
              <DashboardChart
                options={options}
                series={chartSeries}
                chartType={"line"}
                title={
                  isChart && isChart[0]?.toUpperCase() + isChart?.substring(1)
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
