import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { phoneRegex } from '../../utils/Regex';
import { createAdmin } from '../../services/super-admin.service';

import BackIcon from '../../assets/images/back_icon.svg'
import CreateIcon from '../../assets/images/create_icon.svg'

import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";


const CreateAdmin = ({ back }) => {

    const handleClose = () => {

        resetForm();
        setErrors({});
        back(false)
    }

    // form 

    const { handleBlur, handleChange, handleSubmit, values, errors, touched, resetForm, setErrors } = useFormik({
        initialValues: {
            firstName: null,
            lastName: null,
            mobile: null,
            email: null
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .max(50, "First Name must be at most 50 characters")
                .required("FirstName is required"),
            lastName: Yup.string()
                .max(50, "Last Name must be at most 50 characters")
                .required("Lastname is required"),
            mobile: Yup.string()
                .notRequired()
                .matches(phoneRegex, "Mobile number is not valid")
                .nullable(true),
            email: Yup.string().email("Invalid Email Id").required("Email is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {

                const payload = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    mobile: values.mobile,
                    email: values.email,
                    role_id: 3
                }

                const res = await createAdmin(payload)

                if (res?.status === 200 && res?.data?.statusCode === 200) {
                    toast.success(res.data.message)
                    resetForm();
                    setErrors({});
                    back()

                } else {
                    toast.error(res?.message || res?.data?.message);
                }
            } catch (error) {
                if (error?.message) {
                    const msg = error?.message?.split(":")?.[1]?.trim();
                    console.error(msg)


                }
                console.log(error.message);
            }
        },
    });

    return (
        <div className='whiteFrame'>
            <div className="mb_24 displayFlex alignItem_center gap12">
                <div>
                    <button
                        className="emptyBtn"
                        type="button"
                        onClick={handleClose}
                    >
                        <img src={ArrowleftIcon} alt="ArrowleftIcon" />
                    </button>

                </div>
                <div>
                    <h5 className='fontSize20 fontWeight600 blackClr_text'> Create Admin</h5>
                </div>
               
            </div>




            <form onSubmit={handleSubmit}>
                <div className='row'> 
                <div className="mb_24 col-md-6">
                    <label
                        className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                        htmlFor="fn"
                    >
                        First Name
                    </label>
                    <input
                        className="MainInput"
                        type="text"
                        placeholder="Enter First Name"
                        id="firstName"
                        name="firstName"
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName ? (
                        <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                            {errors.firstName}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="mb_24 col-md-6">
                    <label
                        className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                        htmlFor="fn"
                    >
                        Last Name
                    </label>
                    <input
                        className="MainInput"
                        type="text"
                        placeholder="Enter Brand Name"
                        id="lastName"
                        name="lastName"
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName ? (
                        <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                            {errors.lastName}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>



                {/* ========= Mobile ========= */}
                <div className="mb_24 col-md-6">
                    <label
                        className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                        htmlFor="mobile"
                    >
                        Mobile
                    </label>
                    <input
                        className="MainInput"
                        type="text"
                        placeholder="Enter Mobile"
                        id="mobile"
                        name="mobile"
                        value={values.mobile}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.mobile && touched.mobile ? (
                        <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                            {errors.mobile}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>

                {/* ========= Email ========= */}
                <div className="mb_24 col-md-6">
                    <label
                        className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                        htmlFor="email"
                    >
                        Email Address
                    </label>
                    <input
                        className="MainInput"
                        type="email"
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                        <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                            {errors.email}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
                </div>
                {/* ========= Submit Button ========= */}
                {/* <div className='justifyContent_end displayFlex'>
                    <div> */}
                        <button type="submit" className="MainButton cyanSky_clr gap8">
                            <img className='width20px' src={CreateIcon} alt="CreateIcon" />
                            <span>Create Admin</span>
                        </button>
                    {/* </div>
                </div> */}
            </form>





        </div>
    )
}

export default CreateAdmin