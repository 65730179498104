
import { Routes, Route, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Authentication from "./authentication/authentication.component";
import Dashboard from '../../components/dashboard/Dashboard'
import Brandingpage from "../../components/branding/Brandingpage";
import Campaign from '../../components/campaign/Campaign'
// import Category from "../category/category.component";
import Layout from "../../components/Layout/Layout";
import ResetPasswordForm from "../../components/reset_password/ResetPassword";
import NotFoundPage from "../../components/not_found_page/NotFoundPage";
import CustomerList from "../../components/user_management/CustomerList";
import AdminList from "../../components/user_management/AdminList";
import BrandDetail from "../../components/branding/brand-detail";
import Voucher from "../../components/voucher/Voucher";
import AllTransaction from "../../components/transaction/AllTransaction";
import Category from "../../components/CategorySetting/Category";
import Customerpage from "../../components/customer/Customerpage";
import CustomerDetails from "../../components/customer/Customer-detail";
import GlobalSetting from "../../components/GlobalSetting/Global-Setting";
import Country from "../../components/Country/Country";
import Viewalltransaction from "../../components/transaction/Viewalltransaction";
import Document from "../../components/DocumentType/Document";
import SupportQuery from "../../components/supportquery/SupportQuery";
import Activitylogs from "../../components/activitylogs/Activitylogs";




const ProtectedRoute = () => {
    const token = Cookies.get('token');
    // Check if the user is authenticated
    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/" />;
    }
    // If authenticated, render the child routes
    return (
        <>
            <Layout />
        </>
    )
};

const SuperAdmin = () => {

    return (
        <Routes>
            <Route index element={<Authentication />} />

            <Route path="/" element={<ProtectedRoute />}  >
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="brand" element={<Brandingpage />} />
                <Route path="customer" element={<Customerpage />} />
                <Route path="customer-detail/:id/:customer_name" element={<CustomerDetails />} />
                <Route path="View-transaction/:id/:merchant_name" element={<Viewalltransaction />} />



                <Route path="brand-detail/:id" element={<BrandDetail />} />
                <Route path="campaign" element={<Campaign />} /> 
                <Route path="transaction" element={<AllTransaction />} />
                <Route path="voucher" element={<Voucher />} />
                <Route path="supportquery" element={<SupportQuery />} />
                <Route path="activitylogs" element={<Activitylogs />} />
                <Route path="setting/category" element={<Category />} />
                <Route path="setting/country" element={<Country/>} />
                <Route path="setting/document" element={<Document/>} />
                <Route path="setting/globalSetting" element={<GlobalSetting />} />
                <Route path="user-mangement/customer" element={<CustomerList />} />
                <Route path="customer-list" element={<CustomerList />} />
                <Route path="user-mangement/admin" element={<AdminList />} />
                <Route path="reset-password" element={<ResetPasswordForm />} />
            </Route>
            <Route path="/*" element={<NotFoundPage />} />
        </Routes>

    )
}

export default SuperAdmin



