import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';
import { selectCurrentUser } from "../../store/user/user.selector";

import { Postmerchantvariable } from "../../services/merchant.service";
import SaveIcon from "../../assets/images/save_icon.svg";
import BackIcon from "../../assets/images/back_icon.svg";
import { fileUpload } from "../../services/common.service";
import { useParams } from 'react-router-dom';
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

const fileType = "Campaign";

const EditSetting = ({ brand = {}, back }) => {

  const user = useSelector(selectCurrentUser)?.userDetail;
  
  const { id } = useParams();
 
  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const today = new Date().toISOString().split("T")[0];

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      fee_type: brand.fee_type,
      fixed_fee: brand.fixed_fee,
      pay_in: brand.pay_in,
      fee: brand.fee,
      
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      fee_type: Yup.string().required(" Fee Type is required"),

      fee: Yup.string()
        .required("Variable Fees  is required")
        .matches(/^[1-9][0-9]*$/, "Min spend must above 0"),
      fixed_fee: Yup.string()
        .required("Fixed Fee   is required")
        .matches(/^[1-9][0-9]*$/, "Fixed Fee must above 0"),
      pay_in: Yup.string()
        .required("Pay In  is required")
        .matches(/^[1-9][0-9]*$/, "pay in must above 0"),
    }),
    onSubmit: async (values, { resetForm }) => {
    
      try {
        const payload = {
          fee_type: values.fee_type,
          fixed_fee: values.fixed_fee,
          brand_id: id,
          pay_in: values.pay_in,
          fee: values?.fee,
          created_by:user.user_id
         
         
        };

        const res = await Postmerchantvariable(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  // ________

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          setFieldValue("logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };

  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
  };
  return (
    <>
      {/* <div className="mb_24 displayFlex alignItem_center gap12"> */}
        {/* <div>
          <button
            className="emptyBtn"
            type="button"
            onClick={handleClose}
          >
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button>
        </div> */}
      <div className="mb_24">
          <h5 className="fontSize16 fontWeight600 blackClr_text">
            Edit Merchant  Variable Setting
          </h5>
        </div>

      {/* </div> */}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="min_spend"
            >
              Variable Fees Type
            </label>
            <select
              className="selectMainInput"
              id="fee_type"
              name="fee_type"
              value={values.fee_type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="Value">Amount</option>
              <option value="Percentage">Percentage</option>
            </select>

            {errors.fee_type && touched.fee_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fee_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Variable Fees
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Variable Fees"
              id="fee"
              name="fee"
              value={values.fee}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.fee && touched.fee ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fee}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Fixed Fee
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Fixed Fee"
              id="fixed_fee"
              name="fixed_fee"
              value={values.fixed_fee}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.fixed_fee && touched.fixed_fee ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fixed_fee}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Pay In
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Pay In"
              id="pay_in"
              name="pay_in"
              value={values.pay_in}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.pay_in && touched.pay_in ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.pay_in}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> Save Changes</span>
        </button>
      </form>
    </>
  );
};

export default EditSetting;
