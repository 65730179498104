import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDropDownList } from "../../services/super-admin.service";
import { getoffer,getcampaignlist } from "../../services/campaign.service";
import CreateCampaign from "./CreateCampaign";

import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import AddIcon from "../../assets/images/add_icon.svg";

import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import ViewCampaign from "./ViewCampaign";
import PaginatedTable from "../common/PaginatedTable";
import { formatCurrency } from "../../utils/helpers/helper";
import { convertToISOString } from "../../services/common.service";
import { exportCampaign } from "../../services/export.service";
import DatePicker from "../DatePicker/DatePicker";
import { useNavigate } from "react-router-dom";

function Campaign() {
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [campaignTable, setCampaignTable] = useState(true);
  const [data, setdata] = useState();
  const [offers, setOffers] = useState(null);
  const [brand, setBrand] = useState([]);
  console.log('brand::: ', brand);
  const [status, setStatus] = useState("");
  const user = useSelector(selectCurrentUser)?.userDetail;
  const navigate = useNavigate();

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });
  const id = user?.userType === "brand" ? user?.brand_id : "";

  const handleShowCreate = () => {
    setShowCreate(true);
    setCampaignTable(false);
  };

  const handleExport = async () => {
    try {
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey,
      };
      await exportCampaign(payload);
    } catch (error) {}
  };

  const handleShowedit = (data) => {
    setdata(data);
    setShowEdit(true);
    setCampaignTable(false);
  };

  const getAlloffer = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0;
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        statusfilter: status || "all"
      };
      const resp = await getoffer(id, requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setOffers(resp?.data?.data);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  // const getAllcampilist = async (offer) => {
  //   const brandid= offer?.brand_id
  //   const offset = (currentPage - 1) * itemsPerPage;
  //   const offsetToUse = offset >= 0 ? offset : 0;
  //   try {
  //     const requestBody = {
  //       offset: offsetToUse,
  //       limit: itemsPerPage,
  //       search: searchKey,
  //       startDate: convertToISOString(range.startDate),
  //       endDate: convertToISOString(range.endDate),
  //     };
  //     const resp = await getcampaignlist(brandid, requestBody);

  //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
  //       setOffers(resp?.data?.data);
  //       setTotalCount(resp?.data?.count);
  //     } else {
  //       toast.error(resp?.message || resp?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();
  //       console.error(msg);
  //     }
  //     console.log(error.message);
  //   }
  // };

  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        
        <>
          {/* <div className="displayFlex alignItem_center gap12"> */}
          {/* <div>
             <button className="emptyBtn" onClick={() => handleShowedit(row)}>
              <img className="CyanSky_filter" src={EditIcon} alt="EditIcon" />
            </button> 
          </div> */}
          <div>
            <button className="emptyBtn" onClick={() => handleShowView(row)}>
              <img src={ViewIcon} alt="ViewIcon" />
            </button>
          </div>
          {/* </div> */}
        </>
      ),

      hidden: false,
      // width: "120",
      // align: "center",
      id: 1,
    },
    {
      heading: () => "Campaign Name",
      cell: (row) => (
        <div className="textWrap_Wrap">
          {row["campaign_name"] ? row["campaign_name"] : "-"}
        </div>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    // row["brand_name"] === " " ? "-" : row["brand_name"]
    {
      heading: () => "Merchant Name",
      cell: (row) => (
        <div className="textWrap_Wrap">
          <a
            className="emptyBtn cursorPointer blackClr_text"
            onClick={() => navigate(`/admin/brand-detail/${row?.brand_id}`)}
          >
            {row["brand_name"]}
          </a>
        </div>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Start Date",

      cell: (row) => (row["start_Date"] ? row["start_Date"] : "-"),
      hidden: false,
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "End Date",
      cell: (row) => (row["end_Date"] ? row["end_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Type",
      cell: (row) => (row["offer_type"] ? row["offer_type"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => "Value",
      cell: (offer) => {
        if (offer?.offer_type === "Value") {
          return <> {formatCurrency(offer?.offer_value)}</>;
        } else if (offer?.offer_type === "Percentage") {
          return <>{offer?.offer_value}%</>;
        } else {
          return <>-</>; // Default value if offer_type is neither 'Value' nor 'Percentage'
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },
    {
      heading: () => "Status",
      cell: (row) => {
        console.log('row::: ', row);
        const status = row["active_flag"];
        console.log('status::: ', status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
          
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"} style={style}>{displayText}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  useEffect(() => {
    getAlloffer();
    const getBrand = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;

      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
        };

        const res = await getDropDownList();

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setBrand(res?.data?.getDropDownList || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getBrand();
    // getAllcampilist()
  }, [currentPage, itemsPerPage, searchKey, setSearchKey, range,status]);

  const handleShowView = (offer) => {
    console.log('offer::: ', offer);
    setCampaignTable(false);
    setdata(offer);
    setShowView(true);
  };

  const back = (cond = true) => {
    cond && getAlloffer();
    setShowCreate(false);
    setShowEdit(false);
    setShowView(false);
    setCampaignTable(true);
  };

  const backToDetail = () => {
    setShowCreate(false);
    setShowEdit(false);
    setShowView(true);
    setCampaignTable(false);
  };

  return (
    <div>
      {campaignTable && (
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                Campaigns List
              </h5>
            </div>
            <div className="displayFlex justifyContent_spacebetween">
              {/* <div className="pr_10"> <DatePicker range={range} setRange={setRange} /></div> */}
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShowCreate}
              >
                <img src={AddIcon} alt="AddIcon" className="width20px" />
                <span> Create Campaign </span>
              </button>
            </div>
          </div>

          {isLoading ? (
            <PaginatedTable
              row={offers}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              setRange={setRange}
              status={status}
              setStatus={setStatus}
              isstatus={true}
              range={range}
              isExport={true}
              isDateRange={true}
              handleExport={() => handleExport()}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {showCreate && <CreateCampaign brand={brand} back={back} />}

      {showView && (
        <ViewCampaign
         campainlist={offers}
          brand={brand}
          campaign={data}
          back={back}
          handleShowedit={handleShowedit}
        />
      )}
    </div>
  );
}

export default Campaign;
