import axios from 'axios';
import Cookies from 'js-cookie';
// import { SessionStorage } from './SessionStorage';

const url = process.env.REACT_APP_API_BASE_URL;
let requestCounter = 0

export const KrtiPayService = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': "application/json"
    }
})




KrtiPayService.interceptors.request.use(function (config) {

    requestCounter++
    document.body.classList.add('loading-indicator');

    const token = Cookies.get('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

KrtiPayService.interceptors.response.use(function (response) {

    requestCounter--;
    if (requestCounter === 0)
        document.body.classList.remove('loading-indicator');
    return response;
}, function (error) {
    requestCounter--;
    if (requestCounter === 0)
        document.body.classList.remove('loading-indicator');

    return Promise.reject(error);
});


