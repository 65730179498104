import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  EditProductDetail,
  getCashbackRules,
} from "../../services/product.services";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";

const fileType = "Cashback";
function Editproduct({ brand = {}, back }) {
  console.log("brand::: ", brand);

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const [privacyPolicy, setPrivacyPolicy] = useState(
    brand?.privacy_policy || ""
  );
  const [termsAndCondition, setTermsAndCondition] = useState(
    brand?.terms_condition || ""
  );
  const [stepsToRedeem, setStepsToRedeem] = useState(
    brand?.step_to_redeem || ""
  );

  // const user = useSelector(selectCurrentUser)?.userDetail;

  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const formattedToday = new Date().toISOString().split("T")[0];
  // const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  const initialRedemptionType = brand?.redemption_type === "multirredemption";

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(
    initialRedemptionType
  );

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;

    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      product_name: brand?.[0]?.product_name,
      sku: brand?.[0]?.sku,
      upc: brand?.[0]?.upc,
      logo: brand?.[0]?.logo,
      selectedLogo: brand?.[0]?.logo,
      product_description:brand?.[0]?.product_description
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      product_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        )
        .max(50, "Product  name must be at most 50 characters")
        .required("Product name is required"),
      logo: Yup.string().required("Product logo is required"),
      sku: Yup.string().required("Sku  is required")   .min(5, "SKU must be at least 5 characters long")  // Adjust the minimum length as needed
      .max(20, "SKU cannot be more than 20 characters long"),
      upc: Yup.string().required("Upc is required").matches(/^\d{8}$|^\d{12}$/, 'UPC must contain between 8 and 12 digits'),
      product_description: Yup.string().required("Product Description is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
          brand_id: id,
          product_id:brand?.[0]?.id
         
        };

        const res = await EditProductDetail(payload);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const Cashbackoption = [
    { key: "Product", value: "Product" },
    { key: "Cart", value: "Cart" },
    { key: "Promotions", value: "Promotions" },
  ];

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("offer_value", "");
  };
  useEffect(() => {
    // const fetchmerchantCategory = async () => {
    //   try {
    //     const resp = await GetMerchantCategory(id);
    //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
    //       setCategoryList(resp?.data?.data);
    //     } else {
    //       toast.error(resp?.message || resp?.data?.message);
    //     }
    //   } catch (error) {
    //     if (error?.message) {
    //       const msg = error?.message?.split(":")?.[1]?.trim();
    //       console.error(msg);
    //     }
    //     console.log(error.message);
    //   }
    // };
    // fetchmerchantCategory();
  }, []);

  return (
    <>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div>
          {/* <h5 className="fontSize20 fontWeight600 blackClr_text">
            Edit CashBack Rules{" "}
          </h5> */}
        </div>
        <div>
          {/* <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={handleClose}
          >
            <img src={BackIcon} alt="BackIcon" />
            <span> Back </span>
          </button> */}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb_24">
          <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
             Product Logo
          </label>
          {!values?.selectedLogo && (
            <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
              <img src={UploadImg} alt="UploadImg" />
              <span>Upload Product Logo</span>
              <input
                id="selectedLogo"
                type="file"
                name="logo"
                value={values.logo}
                accept="image/*" // Accept all image file types
                onChange={handleFileChange}
                hidden
              />
            </label>
          )}

          {values?.selectedLogo && (
            <div className="ImgFrame position_relative">
              <img
                className="brandImgSelectlogo"
                src={values?.selectedLogo}
                width={100}
                height={100}
                alt="selected logo"
              />
              <div className="imgcloseBtn ">
                <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
              </div>
            </div>
          )}

          {!values.logo && touched.logo ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.logo}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Product Name
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Product Name"
            id="product_name"
            name="product_name"
            value={values.product_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.product_name && touched.product_name ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.product_name}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            SKU
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter SKU"
            id="sku"
            name="sku"
            value={values.sku}
            onBlur={handleBlur}
            onChange={handleChange}
            minLength={5}
            maxLength={20}
          />
          {errors.rules_name && touched.sku ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.sku}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            UPC
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter UPC"
            id="upc"
            name="upc"
            value={values.upc}
            onBlur={handleBlur}
            onChange={handleChange}
            minLength={8}
            maxLength={12}
          />
          {errors.upc && touched.upc ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.upc}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Product Description
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Product Description"
            id="product_description"
            name="product_description"
            value={values.product_description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.product_description && touched.product_description ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.product_description}
            </p>
          ) : (
            <></>
          )}
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>Save Product</span>
        </button>
      </form>
    </>
  );
}

export default Editproduct;
