import React, { useEffect, useState } from "react";
import { addlocation } from "../../utils/validators/validators";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { registerBrand, Counrtylist } from "../../services/super-admin.service";
import { getCategory } from "../../services/merchant.service";
import { createLocation } from "../../services/Location.service";


import CloseIcon from "../../assets/images/close_icon.svg";
import { fileUpload } from "../../services/common.service";
import UploadImg from "../../assets/images/upload_img.svg";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { selectcountry } from "../../store/countrylist/country.selector";
import Select from "react-select";
import { useParams } from "react-router-dom";
const fileType = "Merchant";

function CreateLocation({ back }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [Category, setCategory] = useState([]);
  const [dialCode, setDialCode] = useState("");

  const { id } = useParams();
  console.log('id::: ', id);
  const handleSelectChange = (selectedOptions) => {
    setFieldValue(selectedOptions);
  };

  const Rewardtype = [
    { key: "Open Loop", value: "openLoop" },
    { key: "Closed Loop", value: "closedLoop" },
  ];

  const storetype = [
    { key: "Offline", value: "OFFLINE" },
    { key: "Online", value: "ONLINE" },
    { key: "Both", value: "BOTH" },
  ];

  const handleTypeChange = (e) => {
    setSelectedOptions(e);
    setFieldValue("category_type", e);
  };

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const handleTypeChange1 = (bool) => {
    if (bool) {
      setFieldValue("is_single_store", true);
    } else {
      setFieldValue("is_single_store", false);
    }

    setFieldValue("store_count", 1);
  };

  const AddNewBrandFormik = useFormik({
    initialValues: {
      // name: null,
      // website_url: null,
      contact_name: null,
      email: null,
      mobile: null,
      // logo: null,
      // selectedLogo: null,
      // merchant_vat_id: null,
      country: null,
      city: null,
      // reward_type: null,
      // category_type: null,
      dial_code: null,
      latitude: null,
      longitude: null,
      // android_package_name: null,
      // ios_package_name: null,
      // ios_package_url: null,
      // android_package_url: null,
      location: null,
      // store_count: null,
      // store_type: null,
    },
    validationSchema: addlocation,
    onSubmit: async (
      {
        // name,
        // website_url,
        contact_name,
        mobile,
        email,
        logo,
        merchant_vat_id,
        country,
        city,
        reward_type,
        category_type,
        latitude,
        ios_package_name,
        android_package_name,
        ios_package_url,
        android_package_url,
        longitude,
        location,
        store_count,
        store_type,
      },
      { resetForm }
    ) => {
      try {
        const payLoad = {
          // name,
          // website_url,
          contact_name,
          mobile,
          email,
          // brand_logo: logo,
          // merchant_vat_id,
          country,
          city,
          // reward_type,
          // category_type: selectedOptions,
          dial_code: dialCode,
          latitude,
          longitude,
          location,
          parent_merchant_id:id
          // ios_package_name,
          // android_package_name,
          // ios_package_url,
          // android_package_url,
          // store_count: +store_count,
          // store_type,
          // is_single_store: values.is_single_store,
        };

        const res = await createLocation(payLoad);
        console.log('res::: ', res);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          sessionStorage.setItem("userId", res.data.data);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.log(msg);
        }
        console.log(error.message);
        console.log("error::: ", error);
      }
    },
  });

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    setErrors,
  } = AddNewBrandFormik;

  console.log("er--------------------",errors)
  console.log("va--------------------",values)


  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          setFieldValue("logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };

  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
  };

  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();

  // const handleCountryChange = (e) => {
  //   const selectedCountry = e.target.value;
  //   console.log('selectedCountry::: ', selectedCountry);
  //   const country = countries.find((c) => c.name === selectedCountry);
  //   const selectedDialCode = country ? country.dialCode : "";
  //   setDialCode(selectedDialCode);

  // };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;

    setFieldValue("country", selectedCountry);

    const country = countries.find((c) => c.name === selectedCountry);
    const selectedDialCode = country ? country.dialCode : "";

    setDialCode(selectedDialCode);
    setFieldValue("dial_code", selectedDialCode);
  };

  useEffect(() => {
    // getAlloffer()
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          const countries = res.data.data.map((country) => ({
            name: country.name,
            dialCode: country.dial,
          }));
          dispatch(setcountry(countries));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    const getCategorylist = async () => {
      try {
        const res = await getCategory();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          if (res?.data?.data) {
            setCategory(
              res?.data?.data?.map((category) => ({
                label: category.category_name,
                value: category.id,
              }))
            );
          }
          // console.log("res::: ", res);
          // dispatch(setcountry(res?.data?.data));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getCountry();
    getCategorylist();
  }, [dispatch]);

  return (
    <div className="whiteFrame">
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween"> */}
      <div className="displayFlex alignItem_center gap12 mb_24">
        {/* <div>
          <button onClick={handleClose} className="emptyBtn">
            {" "}
            <img className="black_filter" src={ArrowleftIcon} alt="backIcon" />
          </button>
        </div> */}
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Create Location
          </h5>
        </div>
      </div>
      {/* <div>
          <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={handleClose}
          >
            <img src={BackIcon} alt="AddIcon" />
            <span> Back </span>
          </button>
        </div> */}
      {/* </div> */}

      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* ========= Contact========= */}

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Contact Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Contact Name"
              id="contact_name"
              name="contact_name"
              value={values.contact_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.contact_name && touched.contact_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.contact_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Email ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="MainInput"
              type="email"
              placeholder="Enter Email"
              id="email"
              name="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.email}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Status ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Country
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              name="country"
              value={values.country}
              onBlur={handleBlur}
              onChange={handleCountryChange}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {errors.country && touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= City ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              City
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter City"
              id="city"
              name="city"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.city && touched.city ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.city}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Location=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Location
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Location"
              id="location"
              name="location"
              value={values.location}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.location && touched.location ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.location}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Mobile
            </label>

            <div className="row">
              <div className="col-md-2 pRzero">
                <div className="MainInputFrame">{dialCode}</div>
              </div>
              <div className="col-md-10">
                <input
                  className="MainInput flex-grow-1"
                  type="text"
                  placeholder="Enter Mobile"
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>

            {errors.mobile && touched.mobile ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.mobile}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Latitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Latitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Latitude"
              id="latitude"
              name="latitude"
              value={values.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.latitude && touched.latitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.latitude}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Longitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Longitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Longitude"
              id="longitude"
              name="longitude"
              value={values.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.longitude && touched.longitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.longitude}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap8">
          <img className="width20px" src={CreateIcon} alt="CreateIcon" />
          <span> Save Location</span>
        </button>
      </form>
    </div>
  );
}

export default CreateLocation;
