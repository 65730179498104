export const getAuthToken = () => {
  const token = localStorage.getItem("auth_token");
  return token ?? null;
};

const currency = process.env.REACT_APP_CURRENCY;

export function formatCurrency1(value) {
  try {
    // Ensure the input is treated as a number
    const number = Number(value);
    if (isNaN(number)) {
      return `${currency} ${value}`
    }
    if (!currency) return `${currency} ${value}`
    // Determine the appropriate number of fraction digits
    const hasDecimalPart = number % 1 !== 0;
    const minimumFractionDigits = hasDecimalPart ? 2 : 0;
    const maximumFractionDigits = hasDecimalPart ? 2 : 0;
    return number.toLocaleString('en-KW', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });
  } catch (error) {
    // Handle errors gracefully by returning a default format
    console.error(error.message);
    return `${currency} ${value}`;
  }
}


export function formatCurrency(value, currencyType = currency) {
  try {
    // Ensure the input is treated as a number
    const number = Number(value);
    if (isNaN(number)) {
      return `KWD ${value || 0}`
    }


    // Determine the appropriate number of fraction digits
    // const hasDecimalPart = number % 1 !== 0;
    // const minimumFractionDigits = hasDecimalPart ? 3 : 0;
    // const maximumFractionDigits = hasDecimalPart ? 3 : 0;
   // Set the number of fraction digits
    const minimumFractionDigits = 3;
    const maximumFractionDigits = 3;

    if (!currencyType) {
      // Format the number with commas
      return number.toLocaleString('en-KW', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
      });
    }

    // Format the number using toLocaleString
    let formattedNumber = number.toLocaleString('en-KW', {
      style: 'currency',
      currency: currencyType,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });

    // Replace the currency code with the desired symbol if the currency is KWD
    if (currency === 'KWD') {
      formattedNumber = formattedNumber.replace('KD', 'KWD');
    }
    // 'د.ك'
    return formattedNumber;
  } catch (error) {
    // Handle errors gracefully by returning a default format
    console.error(error.message);
    return `KWD ${value}`;
  }
}


export function calculatePercentage(amount, percentage) {
  const result = (percentage / 100) * amount;
  return Number.isInteger(result) ? result : parseFloat(result.toFixed(2));
}