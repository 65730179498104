import { KrtiPayService } from './api'
const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const exportMerchant = async (req) => {
    let { startDate, endDate, search } = req
    try {
        window.open(`${krtiWebUrl}/merchant-management/export-merchant?startDate=${startDate}&endDate=${endDate}&search=${search}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const exportCampaign = async (req) => {
    let { startDate, endDate, search } = req
    try {
        window.open(`${krtiWebUrl}/campaign-mangement/export-campaign?startDate=${startDate}&endDate=${endDate}&search=${search}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const exportVoucher = async (req) => {
    let { startDate, endDate, search } = req
    try {
        window.open(`${krtiWebUrl}/voucher-management/export-voucher?startDate=${startDate}&endDate=${endDate}&search=${search}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const exportCustomer = async (req) => {
    let { startDate, endDate, search } = req
    try {
        window.open(`${krtiWebUrl}/dashboard/export-customer?startDate=${startDate}&endDate=${endDate}&search=${search}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const exportAdmin = async (req) => {
    let { startDate, endDate } = req
    try {
        window.open(`${krtiWebUrl}/dashboard/getAllAdminList?startDate=${startDate}&endDate=${endDate}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const exportUser = async (req) => {
    let { startDate, endDate } = req
    try {
        window.open(`${krtiWebUrl}/dashboard/getAllUsers?startDate=${startDate}&endDate=${endDate}`, '_blank')
        return
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}