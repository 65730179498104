import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { EditCampaignDetail } from "../../services/campaign.service";
import SaveIcon from "../../assets/images/save_icon.svg";

import BackIcon from "../../assets/images/back_icon.svg";
import { postCountry } from "../../services/country.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";

const fileType = "Voucher";

const  EditCountry = ({ data = {}, back }) => {
  console.log('data::: ', data);

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const today = new Date().toISOString().split("T")[0];

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      name: data.name,
      iso: data?.iso,
      iso3: data?.iso3,
      dial: data?.dial,
      currency_name: data?.currency_name,
      currency: data?.currency,
      currency_symbol: data?.currency_symbol,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Campaign Name must be at most 50 characters")
        .required("Campaign Name is required"),
        iso: Yup.string().required("Iso is required"),
        dial: Yup.string().required("Dial is required"),
        currency_name: Yup.string().required(" Currency name is required"),
        currency: Yup.string().required("Currrency is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log('values::: ', values);
      try {
        const payload = {
          ...values,
          id: data.id,
          // campaign_logo: data.image,
         
        };

        const res = await postCountry(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  // ________

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        console.log('res::: ', res);
        if (res?.data?.statusCode === 200) {
          setFieldValue("logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };

  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
  };

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center gap12">
        <div>
          {/* <button
            className="emptyBtn"
            type="button"
            onClick={handleClose}
          >
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button> */}
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Edit Country
          </h5>
        </div>

      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter  Name"
              id="name"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.name && touched.name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.name}
              </p>
            ) : (
              <></>
            )}
          </div>

    
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Currency Name	
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Currency Name"
              id="currency_name"
              name="currency_name"
              value={values.currency_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.currency_name && touched.currency_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.currency_name}
              </p>
            ) : (
              <></>
            )}
          </div>

    
   
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Currency Code	
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Currency Code	"
              id="currency"
              name="currency"
              value={values.currency}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.currency && touched.currency ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.currency}
              </p>
            ) : (
              <></>
            )}
          </div>

    
   
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Mobile Code	
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Mobile Code	"
              id="dial"
              name="dial"
              value={values.dial}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.dial && touched.dial ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.dial}
              </p>
            ) : (
              <></>
            )}
          </div>

    
    
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Iso
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Iso"
              id="iso"
              name="iso"
              value={values.iso}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.iso && touched.iso ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.iso}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Iso 3
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Iso"
              id="iso3"
              name="iso3"
              value={values.iso3}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.iso3 && touched.iso3 ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.iso3 }
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Currency Symbol
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Currency Symbol	"
              id="currency_symbol"
              name="currency_symbol"
              value={values.currency_symbol}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.currency_symbol && touched.currency_symbol ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.currency_symbol}
              </p>
            ) : (
              <></>
            )}
          </div>

    
        </div>
       

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> Save Changes</span>
        </button>
      </form>
    </div>
  );
};

export default EditCountry;
