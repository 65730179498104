import { KrtiPayService } from './api'

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;

const fileUploadHeader = {
    headers: {
        'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
    }
}


export const fileUpload = async (req, type) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/document-vault/upload/${type}`, req, fileUploadHeader)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const convertToISOString = (dateString) => {
    if (!dateString) return undefined
    const date = new Date(dateString);
    return date.toISOString();
}























export const AdminResetPassword = async (req) => {
    try {
        let payload = {
            old_password: req?.oldPassword,
            new_password: req?.newPassword
        }
        return await KrtiPayService.post(`${krtiWebUrl}/user/reset_password/${req.id}`, payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}





