import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getSupportquery = async (id, requestBody) => {
    const { offset, limit,status, user_id, brand_id, search, startDate, endDate } = requestBody
    console.log('status::: ', status);
    // console.log('statusFilter::: ', statusFilter);
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/support-query/getAllQuery?offset=${offset}&limit=${limit}&search=${search}&status=${status}`,requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const updateSupportStatus = async (payload) => {
    console.log('payload::: ', payload);

    try {
        return await KrtiPayService.post(`${krtiWebUrl}/support-query/updateQueryStatus
        `,payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}





