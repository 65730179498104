import { useFormik } from "formik";
import React from "react";



import Forgotpassword from "../../assets/images/forgot_password_icon.svg";

const ForgotPassword = () => {

  const { values, errors, handleSubmit, handleBlur, handleChange, touched } =
    useFormik({
      initialValues: {
        email: "",
      },

    });

  return (
    <div className="accountWrapper">
      <div className="loginFrame">
        <div className="mb_24 mlr_auto textalign_center">
          <img src={Forgotpassword} alt="Forgotpassword" className="CyanSky_filter" />
        </div>

        <h5 className="textalign_center fontSize18 fontWeight600 blackClr_text mb_8">
          Forgot Password
        </h5>
        <p className="SteelWoolClr_text fontSize14 mb_24 fontWeight500 textalign_center">
          Pleae Enter Your Email Address to Receive a Verification code
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="MainInput"
              id="email"
              type="email"
              name="email"
              placeholder="Enter Email Id"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.email}
              </p>
            ) : (
              <></>
            )}
          </div>
          <button className="MainButton cyanSky_clr width100per" type="submit">
            Send OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
