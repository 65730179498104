import { createSlice } from "@reduxjs/toolkit";

export const ROLE_INITIAL_STATE = {
    rname: null
}

export const roleSlice = createSlice({
    name: "role",
    initialState: ROLE_INITIAL_STATE,
    reducers: {
        setRole(state, action) {
            state.rname = action.payload
        }
    }
})

export const { setRole } = roleSlice.actions;
export const roleReducer = roleSlice.reducer;
