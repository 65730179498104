import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCustomerList } from "../../services/super-admin.service";
import PaginatedTable from "../common/PaginatedTable";

const CustomerList = () => {
  const [list, setList] = useState([]);
   //Paginated Table Variables
   let [totalCount, setTotalCount] = useState(0);
   let [currentPage, setCurrentPage] = useState(1);
   let [itemsPerPage, setItemsPerPage] = useState(10);
   let [searchKey, setSearchKey] = useState("");
   let [isLoading, setIsLoading] = useState(true);
   const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
   const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
   const [status, setStatus] = useState("");

  const [column, setColumn] = useState([
    // {
    //   heading: () => "S.No",
    //   cell: (row, i) => {
    //     return i;
    //   },
    //   hidden: false,
    //   width: "20",
    //   id: 1,
    // },
    {
      heading: () => "First Name",
      cell: (row) => (row["first_name"] ? row["first_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => "Last Name",
      cell: (row) => (row["last_name"] ? row["last_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Country",
      cell: (row) => (row["country"] ? row["country"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    {
      heading: () => "Email",
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Mobile",
      cell: (row) => (row["mobile"] ? row["mobile"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },

    {
      heading: () => "Status",
      cell: (row) => (
        <span  className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"}>
          {row["active_flag"] ? "Active" : "In Active"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },
  ]);

  useEffect(() => {
    const customerList = async () => {
        const offset = (currentPage - 1) * itemsPerPage;
        const offsetToUse = offset >= 0 ? offset : 0
      try {
        
            const requestBody = {
              offset: offsetToUse,
              limit: itemsPerPage,
              search: searchKey,
              statusfilter: status || "all"
            };
        let res = await getCustomerList(requestBody);
      

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setList(res?.data?.data);
          setTotalCount(res?.data?.count);
        } else {
          (res?.message || res?.data?.message) &&
            toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();

          console.error(msg);
        }
        console.log(error.message);
      }
    };
    customerList();
  }, [currentPage, itemsPerPage, searchKey]);

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div></div>
        <div></div>
      </div>
      <div>
        <div className="mb_24">
          <h2 className="fontSize20 fontWeight600 blackClr_text">
            Customers List
          </h2>
        </div>

       

        {isLoading ? (
            <PaginatedTable
              row={list}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={false}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
            //   setRange={setRange}
            //   range={range}
              isExport={false}
              isDateRange={false}
              status={status}
              setStatus={setStatus}
              isstatus={false}
            //   handleExport={() => handleExportTransactions()}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
      </div>
    </div>
  );
};

export default CustomerList;
