import React, { useCallback, useMemo,useState,useEffect } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
  Editor,
  Transforms,
  Range,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CodeIcon from '@mui/icons-material/Code';

import { Button, MaterialIconButton,Toolbar } from './Component'
import { Node } from 'slate'
import escapeHtml from 'escape-html'
import { Text } from 'slate'
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";

 



const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}


  
  const LIST_TYPES = ['numbered-list', 'bulleted-list'];
  const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
  
  const TextEditor = ({ value = [], onChange, setFieldValue, onBlur, fieldName, id, name,values }) => {
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  
    useEffect(() => {
      Transforms.setNodes(editor, { children: value });
    }, [value, editor]);
  
    const handleEditorChange = (newValue) => {
      onChange(newValue);
      handleSerialize();
    };
  
    const handleSerialize = () => {
      const serializedValue = serialize(editor.children);
      setFieldValue(fieldName, serializedValue);
    };
  
    const serialize = nodes => {
      try {
        if (!Array.isArray(nodes)) {
          return '';
        }
  
        return nodes.map(node => {
          if (Text.isText(node)) {
            let string = escapeHtml(node.text);
            if (node.bold) {
              string = `<strong>${string}</strong>`;
            } else if (node.italic) {
              string = `<em>${string}</em>`;
            } else if (node.underline) {
              string = `<u>${string}</u>`;
            }
            return string;
          }
  
          const children = node.children.map(n => serialize([n])).join('');
  
          let serializedNode = '';
          switch (node.type) {
            case 'block-quote':
              serializedNode = `<blockquote><p>${children}</p></blockquote>`;
              break;
            case 'paragraph':
              serializedNode = `<p>${children}</p>`;
              break;
            case 'link':
              serializedNode = `<a href="${escapeHtml(node.url)}">${children}</a>`;
              break;
            case 'bulleted-list':
              serializedNode = `<ul>${children}</ul>`;
              break;
            case 'numbered-list':
              serializedNode = `<ol>${children}</ol>`;
              break;
            case 'list-item':
              serializedNode = `<li>${children}</li>`;
              break;
            case 'heading-one':
              serializedNode = `<h1>${children}</h1>`;
              break;
            case 'heading-two':
              serializedNode = `<h2>${children}</h2>`;
              break;
            default:
              serializedNode = children;
          }
  
          switch (node.align) {
            case 'left':
              return `<div style="text-align: left;">${serializedNode}</div>`;
            case 'right':
              return `<div style="text-align: right;">${serializedNode}</div>`;
            case 'center':
              return `<div style="text-align: center;">${serializedNode}</div>`;
            case 'justify':
              return `<div style="text-align: justify;">${serializedNode}</div>`;
            default:
              return serializedNode;
          }
        }).join('');
      } catch (error) {
        console.error('Error occurred during serialization:', error);
        return '';
      }
    };
  
    const deserialize = (html) => {
      const parseNode = (node) => {
        if (node.type === "text") {
          let attributes = {};
          if (node.parent && (node.parent.name === 'strong' || node.parent.name === 'em' || node.parent.name === 'u')) {
            if (node.parent.name === 'strong') attributes.bold = true;
            if (node.parent.name === 'em') attributes.italic = true;
            if (node.parent.name === 'u') attributes.underline = true;
          }
          return { ...attributes, text: node.data };
        }
    
        switch (node.name) {
          case 'p':
            return { type: 'paragraph', children: node.children ? node.children.map(parseNode) : [] };
          case 'a':
            return { type: 'link', url: node.attribs.href, children: node.children ? node.children.map(parseNode) : [] };
          case 'blockquote':
            // Assuming all block quotes are wrapped in a paragraph tag
            return { type: 'block-quote', children: [{ type: 'paragraph', children: node.children ? node.children.map(parseNode) : [] }] };
          case 'ul':
            return { type: 'bulleted-list', children: node.children ? node.children.map(parseNode) : [] };
          case 'ol':
            return { type: 'numbered-list', children: node.children ? node.children.map(parseNode) : [] };
          case 'li':
            return { type: 'list-item', children: node.children ? node.children.map(parseNode) : [] };
          case 'h1':
            return { type: 'heading-one', children: node.children ? node.children.map(parseNode) : [] };
          case 'h2':
            return { type: 'heading-two', children: node.children ? node.children.map(parseNode) : [] };
          case 'div':
            // Assuming divs are used for text alignment
            const align = node.attribs.style.split(":")[1].trim();
            return { align, children: node.children ? node.children.map(parseNode) : [] };
          default:
            // Check if the node has only one child
            if (node.children && node.children.length === 1) {
              return parseNode(node.children[0]);
            } else {
              return node.children ? node.children.map(parseNode) : [];
            }
        }
      };
    
      try {
        const handler = new DomHandler();
        const parser = new Parser(handler);
    
        parser.write(html || '');
        parser.end();
    
        const document = handler.dom.map(parseNode);
        return document.length > 0 ? document : [{ type: 'paragraph', children: [{ text: '' }] }];
      } catch (error) {
        console.error('Error occurred during deserialization:', error);
        return [{ type: 'paragraph', children: [{ text: 'Error occurred during deserialization' }] }];
      }
    };
  
    return (
      <Slate editor={editor} value={value} 
       onChange={handleEditorChange} initialValue = {(values !== undefined && values !== null) ? values : initialValue} values={values}>
        <Toolbar>
          <MarkButton format="bold" icon={<FormatBoldIcon />} />
          <MarkButton format="italic" icon={<FormatItalicIcon />} />
          <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
          <MarkButton format="code" icon={<CodeIcon />} />
          <AddLinkButton format="link" icon={<AddLinkIcon />} />
          <LinkOffButton format="link" icon={<LinkOffIcon />} />
          <BlockButton format="heading-one" icon={<LooksOneIcon />} />
          <BlockButton format="heading-two" icon={<LooksTwoIcon />} />
          <BlockButton format="block-quote" icon={<FormatQuoteIcon />} />
          <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
          <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
          <BlockButton format="left" icon={<FormatAlignLeftIcon />} />
          <BlockButton format="center" icon={<FormatAlignCenterIcon />} />
          <BlockButton format="right" icon={<FormatAlignRightIcon />} />
          <BlockButton format="justify" icon={<FormatAlignJustifyIcon />} />
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          id={id}
          name={name}
          onBlur={handleSerialize}
          style={{ minHeight: '150px', padding: '10px', borderRadius: '10px', border: '1px solid #ccc' }}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    );
  };
  
  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);
  
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      };
    }
    Transforms.setNodes(editor, newProperties);
  
    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };
  
  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
  
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url)
    }
  }
  
  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    );
  
    return !!match;
  };
  
  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };
  

  const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    return !!link;
  };

  const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
  };

  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }
  };

  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case 'bulleted-list':
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case 'heading-one':
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case 'heading-two':
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case 'list-item':
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case 'numbered-list':
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      case 'link':
        return (
          <a href={element.url} {...attributes}>
            {children}
          </a>
        );
      default:
        return (
          <p style={{ ...style, margin: '2px 0', padding: '0' }} {...attributes}>
            {children}
          </p>
        );
    }
  };
  
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
  
    if (leaf.code) {
      children = <code>{children}</code>;
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
  
    return <span {...attributes}>{children}</span>;
  };
  
  const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <MaterialIconButton>{icon}</MaterialIconButton>
      </Button>
    );
  };
  
  const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <MaterialIconButton>{icon}</MaterialIconButton>
      </Button>
    );
  };


  const AddLinkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
      <Button
        active={isLinkActive(editor,format)}
        onMouseDown={event => {
          event.preventDefault()
          const url = window.prompt('Enter the URL of the link:')
          if (!url) return
          insertLink(editor, url)
        }}
      >
        <MaterialIconButton>{icon}</MaterialIconButton>
      </Button>
    )
  }
  
  const LinkOffButton = ({ format, icon }) => {
    const editor = useSlate()
  
    return (
      <Button
        active={isLinkActive(editor,format)}
        onMouseDown={event => {
          if (isLinkActive(editor)) {
            unwrapLink(editor)
          }
        }}
      >
        <MaterialIconButton>{icon}</MaterialIconButton>
      </Button>
    )
  }
  
  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    }
  ];
  
  export default TextEditor;