import { KrtiPayService } from "./api";

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;

export const cashbackPriority = async (req) => {
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/cashback-rules/cashbackPriority`,
      req
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const addCashbackRules = async (req) => {
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/cashback-rules/addCashbackRules`,
      req
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const getCashbackRules = async (requestBody) => {
  const { offset, limit, user_id, brand_id,search, startDate,endDate,statusfilter  } = requestBody
  try {
    return await KrtiPayService.get(
      `${krtiWebUrl}/cashback-rules/getCashbackRules/${brand_id}?offset=${offset}&limit=${limit}&search=${search}&statusfilter=${statusfilter}`
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const updateCashBackRules = async (req) => {
  // const { rules_id, status } = payload;
  try {
    return await KrtiPayService.patch(
      `${krtiWebUrl}/cashback-rules/updateCashBackRules`,
      req
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const ActiveCashBackRules = async (payload) => {
  const { rules_id, status } = payload;
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/cashback-rules/activeRules/${rules_id}/${status}`,
      payload
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const removeCashBackRules = async (payload) => {
  const { id } = payload;
  try {
    return await KrtiPayService.delete(
      `${krtiWebUrl}/cashback-rules/removeCashbackRules/${id}`
      // body
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
