import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { addbrand } from "../../utils/validators/validators";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { registerBrand } from "../../services/super-admin.service";
import Eyehide from "../../assets/images/eye_hide.svg";
import Eyeshow from "../../assets/images/eye_show.svg";
import CloseIcon from "../../assets/images/close_icon.svg";
import AddIcon from "../../assets/images/add_icon.svg";

function BrandingComponent({ show, onClose }) {



  const [showPass, setShowPass] = useState(false);
  const [conformpasswordshowPass, setconformpassword] = useState(false);

  useEffect(() => { }, []);

  const AddNewBrandFormik = useFormik({
    initialValues: {
      name: null,
      website_url: null,
      contact_name: null,
      email: null,
      mobile: null,
      status: true,
      password: null, // New field: password
      confirmPassword: null, // New field: confirm password
    },
    validationSchema: addbrand,
    onSubmit: async (
      { name, password, website_url, contact_name, mobile, email },
      { resetForm }
    ) => {
      try {
        const payLoad = {
          name,
          website_url,
          contact_name,
          mobile,
          email,
          password,
          status: true,
        };

        const res = await registerBrand(payLoad);

        console.log({ res });
        if (res?.status === 200) {
          sessionStorage.setItem("userId", res.data.data);
          resetForm();
          onClose(true);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log("error::: ", error);
      }
    },
  });

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    AddNewBrandFormik;
  return (
    <div>
      <Modal
        show={show}
        onHide={() => onClose(false)}
        backdrop="static"
        className="addCoach_popup"
      >
        <Modal.Body>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h2 className="fontSize18 fontWeight600 blackClr_text  ">
                Add Brand
              </h2>
            </div>
            <div>
              <button className="emptyBtn" onClick={() => onClose(false)}>
                <img src={CloseIcon} alt="CloseIcon" />
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {/* =========    Brand Name ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                Brand Name
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Brand Name"
                id="name"
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.name && touched.name ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.name}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= Website URL ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Website URL
              </label>
              <input
                className="MainInput"
                type="website_url"
                placeholder="Enter Website URL "
                id="website_url"
                name="website_url"
                value={values.website_url}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.website_url && touched.website_url ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.website_url}
                </p>
              ) : (
                <></>
              )}
            </div>

            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="fn"
              >
                Contact Name
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Contact Name"
                id="contact_name"
                name="contact_name"
                value={values.contact_name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.contact_name && touched.contact_name ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.contact_name}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= Mobile ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Mobile
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Mobile"
                id="mobile"
                name="mobile"
                value={values.mobile}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.mobile && touched.mobile ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.mobile}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= Email ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                className="MainInput"
                type="email"
                placeholder="Enter Email"
                id="email"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.email && touched.email ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.email}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= Password ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="password"
              >
                Password
              </label>
              <div className="Groupinput">
                <input
                  className="MainInput"
                  type={showPass ? "text" : "password"}
                  placeholder="Enter password"
                  id="password"
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="inputgroup_append">
                  <span className="Groupinput_text">
                    <button
                      type="button"
                      className="emptyBtn"
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? (
                        <img src={Eyeshow} alt="Eyeshow" />
                      ) : (
                        <img src={Eyehide} alt="Eyehide" />
                      )}
                    </button>
                  </span>
                </div>
              </div>

              {errors.password && touched.password ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.password}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* ========= confirm-Password ========= */}
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="confirmPassword"
              >
                Confirm-Password
              </label>
              <div className="Groupinput">
                <input
                  className="MainInput"
                  type={conformpasswordshowPass ? "text" : "password"}
                  placeholder="Enter confirm password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="inputgroup_append">
                  <span className="Groupinput_text">
                    <button
                      type="button"
                      className="emptyBtn"
                      onClick={() =>
                        setconformpassword(!conformpasswordshowPass)
                      }
                    >
                      {conformpasswordshowPass ? (
                        <img src={Eyeshow} alt="Eyeshow" />
                      ) : (
                        <img src={Eyehide} alt="Eyehide" />
                      )}
                    </button>
                  </span>
                </div>
              </div>

              {errors.confirmPassword && touched.confirmPassword ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.confirmPassword}
                </p>
              ) : (
                <></>
              )}
            </div>


            {/* ========= Submit Button ========= */}
            <div className="displayFlex justifyContent_end">
              <div>
                <button type="submit" className="MainButton cyanSky_clr gap8">
                  <img src={AddIcon} alt="AddIcon" />
                  <span>Add brand</span>

                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BrandingComponent;
